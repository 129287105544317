<template>
  <section class="job-detail-section">
    <div style="margin-top: 50px" class="job-detail-outer">
      <div class="auto-container">
        <div class="row">
          <div class="content-column col-lg-8 col-md-12 col-sm-12">
            <div class="job-block-outer">
              <!-- Job Block -->
              <div class="job-block-seven">
                <div class="inner-box">
                  <div class="content">
                    <span v-if="university.logo" class="company-logo"
                      ><img :src="imageurl+university.logo" alt=""
                    /></span>
                    <h4>
                      <a href="#"
                        >{{university.name}}</a
                      >
                    </h4>
                    <ul class="job-info">
                      <!-- <li><span class="icon flaticon-briefcase"></span> Segment</li> -->
                      <li>
                        <span class="icon flaticon-map-locator"></span>
                        {{university.location}},china
                      </li>
                      <li><i class="las la-graduation-cap"></i> {{university.total_student}}</li>

                      <li><i class="las la-graduation-cap"></i> {{ university.international_student }}</li>

                      <li><i class="las la-graduation-cap"></i>{{ university.founded_at}}</li>
                    </ul>
                    <ul class="job-other-info">
                      <li class="time">Full Time</li>
                      <li class="privacy">{{university.type}}</li>
                      <li class="required">Scholarship open</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="job-detail">
              <figure v-if="university.bannar" class="image">
                <img
                  class="w-100"
                  style="height: 300px"
                  :src="imageurl+university.bannar"
                  alt=""
                />
              </figure>
              <h4>Introduction</h4>
              <p v-html="university.introduction">
         
              </p>

              <h4  >About this universiy</h4>
              <p v-html="university.description"  >
              
              </p>

              <h4>Accommodation</h4>
              <p v-html="university.accommodation" >
             
              </p>

              <h4>Application Process</h4>
              <p>
                As a Product Designer, you will work within a Product Delivery
                Team fused with UX, engineering, product and data talent. You
                will help the team design beautiful interfaces that solve
                business challenges for our clients. We work with a number of
                Tier 1 banks on building web-based applications for AML, KYC and
                Sanctions List management workflows. This role is ideal if you
                are looking to segue your career into the FinTech or Big Data
                arenas.
              </p>
            </div>
            <!-- Other Options -->
            <div class="other-options">
              <div class="social-share">
                <h5>Share</h5>
                <a href="#" class="facebook"
                  ><i class="fab fa-facebook-f"></i> Facebook</a
                >
                <a href="#" class="twitter"
                  ><i class="fab fa-twitter"></i> Twitter</a
                >
                <a href="#" class="google"
                  ><i class="fab fa-google"></i> Google+</a
                >
              </div>
            </div>

            <!-- <div class="map-outer">
              <div
                class="map-canvas"
                data-zoom="12"
                data-lat="-37.817085"
                data-lng="144.955631"
                data-type="roadmap"
                data-hue="#ffc400"
                data-title="Envato"
                data-icon-path="images/resource/map-marker.png"
                data-content="Melbourne VIC 3000, Australia<br><a href='mailto:info@youremail.com'>info@youremail.com</a>"
              ></div>
            </div> -->

         
          </div>

          <div class="sidebar-column col-lg-4 col-md-12 col-sm-12">
            <aside class="sidebar">
              <div class="btn-box">
                <router-link :to="{ name: 'programs' }" class="theme-btn btn">SEE PROGRAM</router-link>
                <button class="bookmark-btn">
                  <i class="flaticon-bookmark"></i>
                </button>
              </div>

              <div class="sidebar-widget">
                <!-- Job Overview -->
                <h4 class="widget-title">Program Overview</h4>
                <div class="widget-content">
                  <ul class="job-overview">
                    <li>
                      <i class="icon icon-calendar"></i>
                      <h5>Total program</h5>
                      <span>40</span>
                    </li>
                    <li>
                      <i class="icon icon-expiry"></i>
                      <h5>Post Graduate Program</h5>
                      <span>10</span>
                    </li>
                    <li>
                      <i class="icon icon-expiry"></i>
                      <h5>Under Graduate Program</h5>
                      <span>10</span>
                    </li>
                    <li>
                      <i class="icon icon-expiry"></i>
                      <h5>Special Program</h5>
                      <span>10</span>
                    </li>
                    <li>
                      <i class="icon icon-expiry"></i>
                      <h5>Scholarship Program</h5>
                      <span>10</span>
                    </li>
                  </ul>
                </div>

                <!-- Job Skills -->
              </div>

              <div class="sidebar-widget contact-widget">
                <h4 class="widget-title">Contact Us</h4>
                <div class="widget-content">
                  <!-- Comment Form -->
                  <div class="default-form">
                    <!--Comment Form-->
                    <form>
                      <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <input
                            type="text"
                            name="username"
                            placeholder="Your Name"
                            required
                          />
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            required
                          />
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <textarea
                            class="darma"
                            name="message"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <button
                            class="theme-btn btn-style-one"
                            type="submit"
                            name="submit-form"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Constant from "@/interceptor/Constant.js";

export default {
  name: "UniversityView",

  data() {
    return {
      university: "",
      imageurl : Constant.filebaseurl+"image/university/",

    };
  },

  methods: {
    getUniversity(id) {
      axios
        .get("/api/web/university/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.university = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted() {
    var id = 41;

    if (id != null) {
      this.getUniversity(id);
      window.scrollTo(0, 0);
    }
  },
};
</script>

