<template>
  <div>
    <!--End Main Header -->

    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/candidate-dashboard">
              <i class="la la-home"></i> Dashboard</a
            >
          </li>
          <li class="active">
            <a href="/candidate-profile"
              ><i class="la la-user-tie"></i>My Resume</a
            >
          </li>
          <!--li><a href="/candidate-resume"><i class="la la-file-invoice"></i>My Resume</a></li-->
          <!--li><a href="/candidate-CvManager"><i class="la la-file-invoice"></i>Cv Manager</a></li-->
          <li>
            <a href="/candidate-findjob"
              ><i class="la la-user-alt"></i>Find jobs</a
            >
          </li>
          <li>
            <a href="/candidate-appliedjob"
              ><i class="la la-briefcase"></i> Applied Jobs
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Faqs Section -->
    <section class="faqs-section">
      <div class="auto-container">
        <h2 class="sec-title text-center">Create Profile</h2>

        <button class="accordionc">Basic Informations</button>
        <div class="panel">
          <br />

          <div class="uploading-outer">
            <div class="uploadButton">
              <input
                class="uploadButton-input"
                type="file"
                @change="handleFileUpload($event)"
              
                id="upload"
                multiple
              />
              <label class="uploadButton-button ripple-effect" for="upload"
                >Picture</label
              >
              <span class="uploadButton-file-name"></span>
            </div>
          </div>

          <form
            @submit.prevent="add_basic_info"
            style="margin-left: 20px; margin-right: 20px; margin-bottom: 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>First Name</label>
                <input type="text" v-model="fname" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Last Name</label>
                <input type="text" v-model="lname" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Date of Birth</label>
                <input
                  class="form-control"
                  v-model="Date_of_Birth"
                  type="date"
                  placeholder="06.04.2020"
                />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Religion</label>
                <input type="text" v-model="religion" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Ethnicity</label>
                <input type="text" v-model="ethnicity" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Gender</label>
                <select v-model="gender">
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Primary Number</label>
                <input type="text" v-model="primary_no" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Nationality</label>
                <input
                  type="text"
                  v-model="nationality"
                  name="name"
                  placeholder=""
                />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Father's Name</label>
                <input type="text" v-model="father_name" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Mother's Name</label>
                <input type="text" v-model="mother_name" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Secondary Contact Number</label>
                <input type="text" v-model="secondary_cont_no" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Emergency Contact Number</label>
                <input type="text" v-model="emergency_cont_no" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Secondary Email</label>
                <input type="email" v-model="secondary_email" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>NID</label>
                <input type="text" v-model="nid" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Passport Number</label>
                <input type="text" v-model="passport_no" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Blood Group</label>
                <input type="text" v-model="blood_group" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Weight</label>
                <input type="text" v-model="weight" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Height</label>
                <input type="text" v-model="height" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Career Informations</button>
        <div class="panel">
          <form
            @submit.prevent="add_career_info"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Objective</label>
                <input type="text" v-model="objective" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Job Level</label>
                <input type="text" v-model="job_level" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Job Nature</label>
                <select v-model="job_nature">
                  <option value="fulltime">Full time</option>
                  <option value="parttime">Part time</option>
                  <option value="contractual">Contractual</option>
                </select>
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Present Salary</label>
                <input type="number" v-model="present_salary" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Present Salary Type</label>
                <input
                  type="text"
                  v-model="present_salary_type"
                  placeholder=""
                />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Expected Salary</label>
                <input type="number" v-model="expected_salary" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Expected Salary Type</label>
                <input
                  type="text"
                  v-model="expected_salary_type"
                  placeholder=""
                />
              </div>

              <!-- Input -->
              <div class="form-group col-md-12">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Certification</button>
        <div class="panel">
          <form
            @submit.prevent="add_certification"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Certification Name</label>
                <input type="text" v-model="cer_name" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Institution</label>
                <input type="text" v-model="location" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Start Date</label>
                <input
                  class="form-control"
                  v-model="start_date"
                  type="date"
                  placeholder="06.04.2020"
                />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>End Date</label>
                <input
                  class="form-control"
                  v-model="end_date"
                  type="date"
                  placeholder="06.04.2020"
                />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Education</button>
        <div class="panel">
          <form
            @submit.prevent="add_education"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Education Level</label>
                <input type="text" v-model="edu_level" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Degree</label>
                <input type="text" v-model="degree_title" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Major</label>
                <input
                  class="form-control"
                  v-model="major"
                  type="text"
                  placeholder=""
                />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Institute</label>
                <input
                  class="form-control"
                  v-model="institute"
                  type="text"
                  placeholder=""
                />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Grade</label>
                <input
                  class="form-control"
                  v-model="result"
                  type="text"
                  placeholder=""
                />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Marks</label>
                <input
                  class="form-control"
                  v-model="marks"
                  type="text"
                  placeholder=""
                />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Passing Year</label>
                <input
                  class="form-control"
                  v-model="passing_year"
                  type="text"
                  placeholder=""
                />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Duration</label>
                <input
                  class="form-control"
                  v-model="duration"
                  type="text"
                  placeholder=""
                />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Achievement</label>
                <input
                  class="form-control"
                  v-model="achievement"
                  type="text"
                  placeholder=""
                />
              </div>

              <!-- Input -->
              <div class="form-group col-md-12">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Experience</button>
        <div class="panel">
          <form
            @submit.prevent="add_experience"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Organization Name</label>
                <input type="text" v-model="org_name" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Type of Organization</label>
                <input type="text" v-model="org_business" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Designation</label>
                <input type="text" v-model="designation" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Department</label>
                <input type="text" v-model="department" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Starting date of job</label>
                <input
                  class="form-control"
                  v-model="from_date"
                  type="date"
                  placeholder="06.04.2020"
                />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Ending date of job</label>
                <input
                  class="form-control"
                  v-model="to_date"
                  type="date"
                  placeholder="06.04.2020"
                />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Currently working</label>
                <select v-model="curr_working" class="chosen-select">
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Responsibilities</label>
                <input type="text" v-model="responsibilities" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label> Area of expertise</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="area_expertise"
                    placeholder=""
                  />
                  <button class="button_plus" @click="addexpertise"></button>
                </div>
                <div v-html="add" class="mt-2"></div>
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Location of the organization</label>
                <input type="text" v-model="org_location" placeholder="" />
              </div>

              <!-- Input -->
              <div
                style="padding-bottom: 50px"
                class="form-group col-lg-6 col-md-12"
              >
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Skills</button>
        <div class="panel">
          <form
            @submit.prevent="add_skills"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Name of the Skill</label>
                <input type="text" v-model="skill_name" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Acquired category</label>
                <select v-model="acquired_from">
                  <option value="self">Self</option>
                  <option value="training">Training</option>
                  <option value="education">Eduction</option>
                  <option value="job">Job</option>
                </select>
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Description</label>
                <input type="text" v-model="description" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-md-12">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Training</button>
        <div class="panel">
          <form
            @submit.prevent="add_training"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Title</label>
                <input type="text" v-model="title" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Type</label>
                <input type="text" v-model="type" placeholder="" />
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Topics covered</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="topics_covereds"
                    placeholder=""
                  />
                  <!--button @click="addbenefit" type="button" class="theme-btn btn-style-one ms-3">Add</button-->
                  <button
                    class="button_plus"
                    @click="add_topics_covered"
                  ></button>
                </div>

                <div v-html="add1" class="mt-2"></div>
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Duration</label>
                <input type="text" v-model="duration" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Year</label>
                <input type="text" v-model="year" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>institute</label>
                <input type="text" v-model="institute" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Location</label>
                <input type="text" v-model="location" placeholder="" />
              </div>

              <!-- Input -->
              <div style="padding-bottom: 50px" class="form-group col-md-12">
                <button class="theme-btn btn-style-one">Save</button>
                <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Extracurricular Activity</button>
        <div class="panel">
          <form
            @submit.prevent="add_extracurricular"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Extra-Curricular Activities</label>
                <input type="text" v-model="activity" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-md-12">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Languages</button>
        <div class="panel">
          <form
            @submit.prevent="add_languages"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Language name</label>
                <input type="text" v-model="language" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Learned_by</label>
                <select v-model="learned_by">
                  <option value="native">Native</option>
                  <option value="official">Official</option>
                  <option value="training">Training</option>
                </select>
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Reading</label>
                <select v-model="reading">
                  <option value="high">high</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Writing</label>
                <select v-model="writing">
                  <option value="high">high</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Speaking</label>
                <select v-model="speaking">
                  <option value="high">high</option>
                  <option value="medium">Medium</option>
                  <option value="low">Low</option>
                </select>
              </div>

              <!-- Input -->
              <div class="form-group col-md-12">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Permanent Address</button>
        <div class="panel">
          <form
            @submit.prevent="add_permanent_address"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Street</label>
                <input type="text" v-model="street" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>House</label>
                <input type="text" v-model="house" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>County</label>
                <input type="text" v-model="county" placeholder="" />
              </div>
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>City</label>
                <input type="text" v-model="city" placeholder="" />
              </div>
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Province</label>
                <input type="text" v-model="province" placeholder="" />
              </div>
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Country</label>
                <input type="text" v-model="country" placeholder="" />
              </div>
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Description</label>
                <input type="text" v-model="description" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-md-12">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Present Address</button>
        <div class="panel">
          <form
            @submit.prevent="add_present_address"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Street</label>
                <input type="text" v-model="street" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>House</label>
                <input type="text" v-model="house" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>County</label>
                <input type="text" v-model="county" placeholder="" />
              </div>
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>City</label>
                <input type="text" v-model="city" placeholder="" />
              </div>
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Province</label>
                <input type="text" v-model="province" placeholder="" />
              </div>
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Country</label>
                <input type="text" v-model="country" placeholder="" />
              </div>
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Description</label>
                <input type="text" v-model="description" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-md-12">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Preference</button>
        <div class="panel">
          <form
            @submit.prevent="add_preference"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Job Category Choice</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="category_choices"
                    placeholder=""
                  />
                  <!--button @click="addbenefit" type="button" class="theme-btn btn-style-one ms-3">Add</button-->
                  <button
                    class="button_plus"
                    @click="add_category_choice"
                  ></button>
                </div>

                <div v-html="add2" class="mt-2"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Keywords preferences</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="keywords"
                    placeholder=""
                  />
                  <!--button @click="addbenefit" type="button" class="theme-btn btn-style-one ms-3">Add</button-->
                  <button class="button_plus" @click="add_keywords"></button>
                </div>

                <div v-html="add3" class="mt-2"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Preferred city list</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="Preferred_city_list"
                    placeholder=""
                  />
                  <!--button @click="addbenefit" type="button" class="theme-btn btn-style-one ms-3">Add</button-->
                  <button
                    class="button_plus"
                    @click="add_Preferred_city"
                  ></button>
                </div>

                <div v-html="add4" class="mt-2"></div>
              </div>

              <div class="form-group col-lg-6 col-md-12">
                <label>Special Skills</label>
                <div style="display: flex">
                  <input
                    type="text"
                    style="width: 80%"
                    v-model="special_skills"
                    placeholder=""
                  />
                  <!--button @click="addbenefit" type="button" class="theme-btn btn-style-one ms-3">Add</button-->
                  <button
                    class="button_plus"
                    @click="add_special_skill"
                  ></button>
                </div>

                <div v-html="add5" class="mt-2"></div>
              </div>

              <!-- Input -->
              <div
                style="padding-bottom: 50px"
                class="form-group col-lg-6 col-md-12"
              >
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Reference</button>
        <div class="panel">
          <form
            @submit.prevent="add_reference"
            style="margin: 20px 20px 20px 20px"
            class="default-form"
          >
            <div class="row">
              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Name</label>
                <input type="text" v-model="name" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Designation</label>
                <input type="text" v-model="designation" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Organization</label>
                <input type="text" v-model="organization" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Email</label>
                <input type="email" v-model="email" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Relation</label>
                <input type="text" v-model="relation" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label>Contact number</label>
                <input type="text" v-model="cell_no" placeholder="" />
              </div>

              <!-- Input -->
              <div class="form-group col-lg-6 col-md-12">
                <label> Full address</label>
                <input type="text" v-model="address" placeholder="" />
              </div>
              <!-- Input -->
              <div class="form-group col-md-12">
                <button type="submit" class="theme-btn btn-style-one">
                  Save
                </button>
                <button
                  id="next-button-1"
                  style="margin-left: 20px; background-color: brown"
                  class="theme-btn btn-style-one"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>
        </div>

        <button class="accordionc">Upload your Resume</button>
        <div class="panel">
          <!-- Dashboard -->
          <section class="user-dashboard">
            <div class="dashboard-outer">
              <div class="row">
                <div class="col-lg-4">
                  <!-- CV Manager Widget -->
                  <div class="cv-manager-widget ls-widget">
                    <div class="widget-content">
                      <div class="uploading-resume">
                        <div class="uploadButton">

                          <input
                            class="uploadButton-input"
                            type="file"
                            @change="handleFileUpload($event)"
                            accept="image/*, application/pdf"
                            id="upload"
                        
                          />
            
                          <label class="cv-uploadButton" for="upload">
                            <span class="title">Drop or click here to select file</span>
                            <span class="text"
                              >To upload file size is (Max 5Mb) and allowed file
                              types are (.doc, .docx, .pdf)</span
                            >
                            <!-- <span class="btn btn-success btn-sm"
                              >Select File</span> -->
                            

                            <span class="uploadButton-file-name font130 "></span>
                          </label>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-lg-4 p-3" >
                  
                            <span @click="addresume" class="theme-btn btn-style-one"
                              >Upload Resume</span>



                </div>
              </div>
            </div>
          </section>
          <!-- End Dashboard -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CandidateProfileView",
  data() {
    return {
      expertise: [],
      topics_covered: [],
      category_choice: [],
      keyword: [],
      Preferred_city: [],
      special_skill: [],
      file: "",

      add: "",
      add1: "",
      add2: "",
      add3: "",
      add4: "",
      add5: "",
    };
  },

  methods: {
    addexpertise() {
      if (this.expertise.length < 10) {
        this.expertise.push(this.area_expertise);
        this.add = "" + this.expertise;
      } else {
        alert("Expertise Max length will be 10");
      }
    },

    add_topics_covered() {
      if (this.topics_covered.length < 10) {
        this.topics_covered.push(this.topics_covereds);
        this.add1 = "" + this.topics_covered;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_category_choice() {
      if (this.category_choice.length < 10) {
        this.category_choice.push(this.category_choices);
        this.add2 = "" + this.category_choice;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_keywords() {
      if (this.keyword.length < 10) {
        this.keyword.push(this.keywords);
        this.add3 = "" + this.keyword;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_Preferred_city() {
      if (this.Preferred_city.length < 10) {
        this.Preferred_city.push(this.Preferred_city_list);
        this.add4 = "" + this.Preferred_city;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_special_skill() {
      if (this.special_skill.length < 10) {
        this.special_skill.push(this.special_skills);
        this.add5 = "" + this.special_skill;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_basic_info() {
      axios
        .post("applicant/basic_info/", {
          first_name: this.fname,
          last_name: this.lname,
          dob: this.Date_of_Birth,
          religion: this.religion,
          ethnicity: this.ethnicity,
          gender: this.gender,
          primary_contact_no: this.primary_no,
          nationality: this.nationality,
          fathers_name: this.father_name,
          mothers_name: this.mother_name,
          secondary_contact_no: this.secondary_cont_no,
          emergency_contact_no: this.emergency_cont_no,
          secondary_mail: this.secondary_mail,
          nid: this.nid,
          passport_no: this.passport_no,
          blood_group: this.blood_group,
          weight: this.weight,
          height: this.height,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_career_info() {
      axios
        .post("applicant/career_info/", {
          objective: this.objective,
          job_level: this.job_level,
          job_nature: this.job_nature,
          present_salary: this.present_salary,
          present_salary_type: this.present_salary_type,
          expected_salary: this.expected_salary,
          expected_salary_type: this.expected_salary_type,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_certification() {
      axios
        .post("applicant/certification/", {
          cer_name: this.cer_name,
          location: this.location,
          start_date: this.start_date,
          end_date: this.end_date,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_education() {
      axios
        .post("applicant/education/", {
          edu_level: this.edu_level,
          degree_title: this.degree_title,
          major: this.major,
          institute: this.institute,
          result: this.result,
          marks: this.marks,
          passing_year: this.passing_year,
          duration: this.duration,
          achievement: this.achievement,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_experience() {
      axios
        .post("applicant/experience/", {
          org_name: this.org_name,
          org_business: this.org_business,
          designation: this.designation,
          department: this.department,
          from_date: this.from_date,
          to_date: this.to_date,
          curr_working: this.curr_working,
          responsibilities: this.responsibilities,
          area_expertise: this.expertise,
          org_location: this.org_location,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_skills() {
      axios
        .post("applicant/skill/", {
          skill_name: this.skill_name,
          acquired_from: this.acquired_from,
          description: this.description,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_training() {
      axios
        .post("applicant/training/", {
          title: this.title,
          type: this.type,
          topics_covered: this.topics_covered,
          duration: this.duration,
          year: this.year,
          institute: this.institute,
          location: this.location,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_extracurricular() {
      axios
        .post("applicant/extracurricular/", {
          activity: this.activity,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_languages() {
      axios
        .post("applicant/language/", {
          language: this.language,
          learned_by: this.learned_by,
          reading: this.reading,
          writing: this.writing,
          speaking: this.speaking,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_permanent_address() {
      axios
        .post("applicant/permanent_address/", {
          street: this.street,
          house: this.house,
          county: this.county,
          city: this.city,
          province: this.province,
          country: this.country,
          description: this.description,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_present_address() {
      axios
        .post("applicant/present_address/", {
          street: this.street,
          house: this.house,
          county: this.county,
          city: this.city,
          province: this.province,
          country: this.country,
          description: this.description,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_preference() {
      axios
        .post("applicant/preference/", {
          job_category_choice: this.category_choice,
          keyword: this.keyword,
          pref_city: this.Preferred_city,
          special_skill: this.special_skill,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_reference() {
      axios
        .post("applicant/reference/", {
          name: this.name,
          designation: this.designation,
          organization: this.organization,
          email: this.email,
          relation: this.relation,
          cell_no: this.cell_no,
          address: this.address,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
    },

    addresume(){


      if(this.file!=""){

        let formData = new FormData();
        formData.append("resume", this.file);

        axios
          .post("/applicant/resume/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              alert("Resume Uploaded");
            }
          })
          .catch((error) => {
            alert(error.response.data.user);
          });


  
      }



    }
  },

  mounted: function(){
  
  },
};
</script>

<style>
</style>



