<template>
<div>
  
  <!-- Faqs Section -->

    <!-- Contact Section -->
    <section class="contact-section">

      <div class="auto-container">

        <h3 class="my-4" >CONTACT US</h3>



        
        <div class="upper-box mt-5" style="background-color: #5DADE2  ">


          <div class="row">
            <div class="contact-block col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <span class="icon"><img src="images/icons/placeholder.svg" alt=""></span>
                <h4>Address</h4>
                <p style="color: black;">128-130 The Grove, Stratford<br>London England, 15 1NS</p>
              </div>
            </div>
            <div class="contact-block col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <span class="icon"><img src="images/icons/smartphone.svg" alt=""></span>
                <h4>Call Us</h4>
                <p><a href="#" style="color: black;" class="phone">+44 7495 587992</a></p>
              </div>
            </div>
            <div class="contact-block col-lg-4 col-md-6 col-sm-12">
              <div class="inner-box">
                <span class="icon"><img src="images/icons/letter.svg" alt=""></span>
                <h4>Email</h4>
                <p><a href="#" style="color: black;">support@hive4work.co.uk</a></p>
              </div>
            </div>
          </div>
        </div>


        <!-- Contact Form -->
        <div class="contact-form default-form mt-3">
          <h3>Leave A Message</h3>
          <!--Contact Form-->
          <form @submit.prevent="addPartTime">
            <div class="row">
              <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <div class="response"></div>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                <label>Your Name</label>
                <input type="text" v-model="name" class="username" placeholder="Your Name*" required>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                <label>Your Email</label>
                <input type="email" v-model="email" class="email" placeholder="Your Email*" required>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <label>Subject</label>
                <input type="text" v-model="subject" class="subject" placeholder="Subject *" required>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <label>Your Message</label>
                <textarea v-model="message" placeholder="Write your message..." required=""></textarea>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <button class="theme-btn btn-style-one" type="submit" id="submit" name="submit-form">Send Massage</button>
              </div>
            </div>
          </form>
        </div>
        <!--End Contact Form -->
      </div>
    </section>
    <!-- Contact Section -->

    <!-- Call To Action -->
    <section class="call-to-action style-two">
      <div class="auto-container">
        
      </div>
    </section>
    <!-- End Call To Action -->
</div>
</template>

<script>

import axios from 'axios'
export default {
  name:'ContactView',



methods: {

direct_message(){
  axios.post("anonymous/direct-message/",
      {
        name:this.name,
        email:this.email,
        subject:this.subject,
        message:this.message,
        
        
      }
      ,{ignore:"1"}).then( res => {

      if(res.status==201){
      console.log(res.data)
      alert("ok")
      }
    
    }

  )
.catch(error => {
console.log(error.response)
});

},


},
  
mounted: function(){
   
   this.direct_message()
    
}
}
</script>

<style>

</style>