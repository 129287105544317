<template>

<div>



  <br /><br /><br />
  <section class="page-title style-two">
      <div class="auto-container">
        <div class="row justify-content-left">
          <div class="col-lg-12">
            <div class="sec-title light">
          <h2 style="font-size: 45px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff" >Hundreds of Applicants Are Waiting for jobs!</h2>
          <div style="font-size: 22px; line-height: 80px font-family: tungstenw05-medium, Oswald, sans-serif; font-weight: 500; color:#fffff" class="text">Over 1 million interactions, 50,000 success stories, Make yours now.</div>
        </div>
          </div>
        </div>

        
        
      </div>
    </section>
    <!--End Page Title-->
  
    <!-- Fun Fact Section -->
    <br /><br />
     <div>
    
    <!--Page Title-->

      <!-- Work Section -->
    <section class=" layout-pb-60">
      <div class="auto-container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="sec-title text-center">
              <h2>How It Works?</h2>
              <div class="text">Do you want to hire people for your organization? Sign up with Hive4Works and publish jobs to reach hundreds of applicants.</div>
            </div>
          </div>
        </div>

        <div class="row grid-base wow fadeInUp">

          <!-- Work Block -->
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="work-block -type-2 mb-0">
              <div class="inner-box">
                <div class="icon-wrap -blue">
                  <span class="icon icon-case"></span>
                </div>
                <h5>Register an account to start</h5>
                <p>Choos a price plan and subscribe with us and post jobs with your own management.</p>
              </div>
            </div>
          </div>

          <!-- Work Block -->
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="work-block -type-2 mb-0">
              <div class="inner-box">
                <div class="icon-wrap -red">
                  <span class="icon icon-contact"></span>
                </div>
                <h5>Explore over thousands of resumes</h5>
                <p>After signing up with us, you will get access hundreds of resume to find the competent applicant.</p>
              </div>
            </div>
          </div>

          <!-- Work Block -->
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="work-block -type-2 mb-0">
              <div class="inner-box">
                <div class="icon-wrap -yellow">
                  <span class="icon icon-doc"></span>
                </div>
                <h5>Find the most suitable candidate</h5>
                <p>Get applications, short list and hire the excellents.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- End Work Section -->
    
    <!-- Pricing Sectioin -->

    <!-- End Pricing Section -->


    </div>

    <!-- Call To Action Three -->
    <section class="call-to-action-three style-two">
      <div class="auto-container wow fadeInUp">
        <div class="outer-box">
          <div class="sec-title light">
            <h2>Gat a quotation?</h2>
            <div class="text">We're here to help. If you want a customized offer, contact us or call us <br><a href="#">+44 7495 587992</a></div>
          </div>

          <div class="btn-box">
            <a href="/contact" class="theme-big-button5">Contact Us</a>
          </div>
        </div>
      </div>
    </section>
    <!-- End Call To Action -->
    <!-- Top Companies -->
    <!-- <section class="playout-pt-60 layout-pb-60">
      <div class="auto-container">
        <div class="row justify-content-between align-items-end">
          <div class="col-lg-6">
            <div class="sec-title mb-0">
              <br>
              <br><br>
              <h2 class="color-blue-dark fw-700">Top Company Registered</h2>
              <div class="text">Some of the companies we've helped recruit excellent applicants over the years.</div>
            </div>
          </div>

          <div class="col-auto">
            <a href="#" class="button -arrow text-dark-blue">
              Browse
              <span class="fa fa-angle-right"></span>
            </a>
          </div>
        </div>

        <div class="carousel-outer pt-50 wow fadeInUp">
          <div class="companies-carousel owl-carousel owl-theme default-dots">
        
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-1.png" alt=""></figure>
                <h4 class="name">Udemy</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">15 Open Position</a>
              </div>
            </div>

      
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-2.png" alt=""></figure>
                <h4 class="name">Stripe</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

        
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-3.png" alt=""></figure>
                <h4 class="name">Dropbox</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

           
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-4.png" alt=""></figure>
                <h4 class="name">Figma</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

          
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-1.png" alt=""></figure>
                <h4 class="name">Udemy</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">15 Open Position</a>
              </div>
            </div>

        
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-2.png" alt=""></figure>
                <h4 class="name">Stripe</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

          
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-3.png" alt=""></figure>
                <h4 class="name">Dropbox</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

    
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-4.png" alt=""></figure>
                <h4 class="name">Figma</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

        
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-1.png" alt=""></figure>
                <h4 class="name">Udemy</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">15 Open Position</a>
              </div>
            </div>

         
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-2.png" alt=""></figure>
                <h4 class="name">Stripe</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

        
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-3.png" alt=""></figure>
                <h4 class="name">Dropbox</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>

          
            <div class="company-block">
              <div class="inner-box">
                <figure class="image"><img src="images/resource/company-4.png" alt=""></figure>
                <h4 class="name">Figma</h4>
                <div class="location"><i class="flaticon-map-locator"></i> London, UK</div>
                <a href="#" class="theme-btn btn-dark-blue">22 Open Position</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!--End Page Title-->
</div>
</template>

<script>
export default {
 name:'CandidatesListView',

 data() {
        return {
          CandidatesList: [
                { CandidatesName: 'Hridoy', Designamtion: 'Software Developer',  location: 'Dhaka', Payment: '$25 hours',App:"app", Design:"design", Digital:"digital"},
                { CandidatesName: 'Tawsif', Designamtion: 'Product Manager',  location: 'Khulna',Payment: '$15 hours',App:"app", Design:"design", Digital:"digital"},
                { CandidatesName: 'Noman',  Designamtion: 'HR', location: 'Chandpur', Payment: '$10 hours',App:"app", Design:"design", Digital:"digital"},
                { CandidatesName: 'Faysal', Designamtion: 'Accounts', location: 'Dhaka', Payment:'$20 hours',App:"app", Design:"design", Digital:"digital"},
            
            ]

            
        };
       


    }
}
</script>

<style>

</style>