<template>
   <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li >
            <router-link :to="{name:'admin-dashboard'}"
              ><i class="la la-paper-plane"></i>Dashboard</router-link>
          </li>
       
          <li >
            <router-link :to="{name:'admin-universitylist'}"
              ><i class="la la-paper-plane"></i>University</router-link>
          </li>
          <li >
            <router-link :to="{name:'categorylist'}"
              ><i class="la la-paper-plane"></i>Category</router-link>
          </li>
          <li>
            <router-link :to="{name:'programlist'}"
              ><i class="la la-paper-plane"></i>Program</router-link>
          </li>
          <li>
            <router-link :to="{name:'courselist'}"
              ><i class="la la-paper-plane"></i>Course</router-link>
          </li>
          <!-- <li>
            <a href="/employer-allapplicants"
              ><i class="la la-file-invoice"></i> All Applicants</a
            >
          </li> -->
          <li>
            <a href="#"
              ><i class="la la-bookmark-o"></i>Applicants</a
            >
          </li>
          <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
         
          <li>
            <a href="#"><i class="la la-lock"></i>Change Password</a>
          </li>

          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <li>
            <router-link :to="{name:'login'}"
              ><i class="la la-paper-plane"></i>Logout</router-link>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>