<template>
    <!--Page Title-->
     <div>
    <br><br><br>
    <section class="page-title style-two">
       <div class="auto-container">
          <div class="row justify-content-center">
           <div class="col-lg-5">
             <div class="sec-title text-center">
               <h2>{{ skey }}</h2>
             </div>
           </div>
         </div>
     
       </div>
     </section>
     <!--End Page Title-->
    
     <!-- Listing Section -->
     <section class="ls-section">
       <div class="auto-container">
         <div class="filters-backdrop"></div>
 
         <div class="row">
           <!-- Content Column -->
           <div class="content-column col-lg-12">
             <div class="ls-outer">
               <!-- ls Switcher -->
               <!-- <div class="ls-switcher">
                 <div class="showing-result">
                   <div class="top-filters">
                     <div class="form-group">
                       <select class="chosen-select">
                         <option>Job Type</option>
                         <option>Full Time</option>
                         <option>Part Time</option>
                         <option>Contractual</option>
                         <option>Internship</option>
                       </select>
                     </div>
 
                     <div class="form-group">
                       <select class="chosen-select">
                         <option>Date Posted</option>
                         <option>Today's Job</option>
                         <option>This week</option>
                         <option>Last week</option>
                         <option>This Month</option>
                         <option>Last 6 months</option>
                         <option>This year</option>
                       </select>
                     </div>
 
                     <div class="form-group">
                       <select class="chosen-select">
                         <option>Experience Level</option>
                         <option>No Experience</option>
                         <option>1 year Experience</option>
                         <option>1-3 years Experience</option>
                         <option>3-6 years Experience</option>
                         <option>6-10 years Experience</option>
                         <option>10+ Years Experience</option>
                       </select>
                     </div>
 
                     <div class="form-group">
                       <select class="chosen-select">
                         <option>Salary estimate</option>
                         <option>Upto 5$/hour</option>
                         <option>Upto 10$/hour</option>
                         <option>Upto 15$/hour</option>
                         <option>Upto 20$</option>
                         <option>Upto 2000$/monthly</option>
                         <option>2000$/monthly</option>
                       </select>
                     </div>
                   </div>
                 </div>
 
                 <div class="sort-by">
                   <select class="chosen-select">
                     <option>Job Type</option>
                         <option>Full Time</option>
                         <option>Part Time</option>
                         <option>Contractual</option>
                         <option>Internship</option>
                   </select>
 
                   <select class="chosen-select">
                     <option>Show 10</option>
                     <option>Show 20</option>
                     <option>Show 30</option>
                     <option>Show 40</option>
                     <option>Show 50</option>
                     <option>Show 60</option>
                   </select>
                 </div>
               </div> -->
 
               <div class="row">
                 <!-- Job Block -->
                 <div v-for="job in  ano_jobs" :key="job.id" class="job-block col-lg-6 ">
                   <div  @click="openjob(job.uuid)"  class="inner-box">
                     <div class="content">
                       <span class="company-logo"><img src="images/resource/company-logo/1-1.png" alt=""></span>
                       <h4 class="c-text-left" >{{job.job_title}}</h4>
                       <ul class="job-info">
                         <li><span class="icon flaticon-briefcase"></span>{{job.recruiter.org_name}}</li>
                         <li><span class="icon flaticon-map-locator"></span>{{job.location}}</li>
                         <li><span class="icon flaticon-clock-3"></span> Expire date:{{job.expire_date}}</li>
                         <li><span class="icon flaticon-money"></span> &pound;{{job.salary_range[0]}}-&pound;{{job.salary_range[1]}}</li>
                       </ul>
                       <ul class="job-other-info">
                         <li class="time">Full time</li>
                         <li class="privacy">Private</li>
                         <li class="required">Urgent</li>
                       </ul>
                       <button class="bookmark-btn"><span class="flaticon-bookmark"></span></button>
                     </div>
                   </div>
                 </div>
                 </div>
 
               
 
               <!-- Pagination -->
               <nav class="ls-pagination">
                 <ul>
                   <li class="prev"><a href="#"><i class="fa fa-arrow-left"></i></a></li>
                   <li><a href="#">1</a></li>
                   <li><a href="#" class="current-page">2</a></li>
                   <li><a href="#">3</a></li>
                   <li class="next"><a href="#"><i class="fa fa-arrow-right"></i></a></li>
                 </ul>
               </nav>
             </div>
           </div>
         </div>
       </div>
     </section>
     <!--End Listing Page Section -->
   </div>
 </template>
 
 <script>
 import axios from 'axios'
 export default {
   name:'JobCategoryView',
   data() {
         return {

            ano_jobs:[],
            skey:'',
            populer_skey:''
          
 
 
             
 
             
         };
     },
 
 methods: {
      
 
 get_jobs(key) 
 { axios.get("anonymous/category/"+key+"/jobs/",{ignore:"1"}).then( res => {
 
 
 if(res.status==200){
   this.ano_jobs=res.data.results

 
    }
 }
 )
 .catch(error => {
 console.log(error.response)
 });
 },
 openjob(uuid){



if(uuid!=null){

    this.$router.push({  name: 'job-single', query: { uuid: uuid } });

   
}
   



}
 
 
 
 },
 
           
 mounted() {
  
  var cat=this.$route.query.cart_id
  var cat_name=this.$route.query.cart_name

  if(cat!=null){

    this.get_jobs(cat)
    this.skey=cat_name
    window.scrollTo(0, 0);

   




  


  }



  }
 
 }
 </script>
 
 <style>
 
 </style>