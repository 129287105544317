<template>
    
    <div>
         <!-- Sidebar Backdrop -->
  
  <!-- End User Sidebar -->
  
   <!-- Dashboard -->
      <section class="user-dashboard">
        <div class="dashboard-outer">
          <div class="upper-title-box">
            <h3>Manage Category</h3>
        
          </div>
  
          <div class="row">
            <div class="col-lg-12">
              <!-- Ls widget -->
              <div class="ls-widget">
                <div class="tabs-box">
                  <div class="widget-title">
                    <h4>Category List</h4>
  
                    <!-- <div class="chosen-outer">
                      <select class="chosen-select">
                        <option>Last 6 Months</option>
                        <option>Last 12 Months</option>
                        <option>Last 16 Months</option>
                        <option>Last 24 Months</option>
                        <option>Last 5 year</option>
                      </select>
                    </div> -->
                 <router-link :to="{ name: 'admin-add-category' }" class="theme-btn btn">Add Category</router-link>
                  </div>
  
                  <div class="widget-content">
                    <div class="table-outer">
                      <table class="default-table manage-job-table">
                        <thead>
                          <tr>
                            <th>name</th>
                           
                              <th>Logo</th>
        
                              <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
  
                        <tbody>
                          <tr v-for=" category in categories" :key="category"  >
                            <td>
                              <h6>{{ category.name }}</h6>
                             
                            </td>
  
                      
                            <td><img style="height: 50px;width: 50px;" :src="imageurl+category.logo" alt=""></td>
                           
                
                            <td class="status">{{category.status}}</td>
                            <td>
                              <div class="option-box">
                                <ul class="option-list">
                                  <li><button data-text="Reject Aplication"><span class="la la-pencil"></span></button></li>
                                  <li><button data-text="Delete Aplication"><span class="la la-trash"></span></button></li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                         
                       
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
  
          </div>
        </div>
      </section>
      <!-- End Dashboard -->
  
  <!-- End Dashboard -->
  
  
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Constant from "@/interceptor/Constant.js";
  
  export default {
  
      name:'EmployerManageJobView',
  
      data(){
        return{
  
         categories:[],
         imageurl : Constant.filebaseurl+"/image/university/",
  
  
        }
  
      },
  
  
  methods: {
    
  
    getCategory() { axios.get("api/admin/category",
  
     ).then( res => {
  
    
       this.categories=res.data.data
  
       if(res.status==200){
  
        console.log("Data received")
     
  
       }
     
     }
  
   )
  .catch(error => {
  console.log(error.response)
  });
   
  },
  
  
  },
  
  mounted:function(){
    this.getCategory()
  }
  
    
  }
  </script>
  
  <style>
  
  </style>