<template>
    
  <div>
       <!-- Sidebar Backdrop -->

<!-- End User Sidebar -->

 <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Manage University</h3>
      
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <h4>University List</h4>

                  <!-- <div class="chosen-outer">
                    <select class="chosen-select">
                      <option>Last 6 Months</option>
                      <option>Last 12 Months</option>
                      <option>Last 16 Months</option>
                      <option>Last 24 Months</option>
                      <option>Last 5 year</option>
                    </select>
                  </div> -->
               <router-link :to="{ name: 'admin-add-university' }" class="theme-btn btn">Add University</router-link>
                </div>

                <div class="widget-content">
                  <div class="table-outer">
                    <table class="default-table manage-job-table">
                      <thead>
                        <tr>
                          <th>University name</th>
                            <th>University code</th>
                            <th>Logo</th>
                            <th>Total Application</th>
                            <th>Scholoarship open</th>
                            <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for=" university in universities" :key="university"  >
                          <td>
                            <h6>{{ university.name }}</h6>
                           
                          </td>

                          <td>{{ university.code }}</td>
                          <td><img style="height: 50px;width: 50px;" :src="imageurl+university.logo" alt=""></td>
                          <td class="applied"><a href="#">23</a></td>
                         
                          <td>{{university.scholarship_open}}</td>
                          <td class="status">{{university.status}}</td>
                          <td>
                            <div class="option-box">
                              <ul class="option-list">
                                <li><button data-text="Reject Aplication"><span class="la la-pencil"></span></button></li>
                                <li><button data-text="Delete Aplication"><span class="la la-trash"></span></button></li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                       
                     
                     
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
    <!-- End Dashboard -->

<!-- End Dashboard -->


</div>
</template>

<script>
import axios from 'axios';
import Constant from "@/interceptor/Constant.js";

export default {

    name:'EmployerManageJobView',

    data(){
      return{

       universities:[],
       imageurl : Constant.filebaseurl+"/image/university/",


      }

    },


methods: {
  

  getUniversity() { axios.get("api/admin/university",

   ).then( res => {

  
     this.universities=res.data.data

     if(res.status==200){

      console.log("Data received")
   

     }
   
   }

 )
.catch(error => {
console.log(error.response)
});
 
},


},

mounted:function(){
  this.getUniversity()
}

  
}
</script>

<style>

</style>