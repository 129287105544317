<template>
       <!-- Copyright -->
    <div class="copyright-text">
    <p>© 2023 Pandaxue. All Right Reserved.</p>
    </div>
  
</template>


<script>
export default {
   name:"FooterView"
}
</script>

<style>

</style>