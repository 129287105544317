<template>
    <div >
        <!-- Sidebar Backdrop -->
        <div class="sidebar-backdrop"></div>
  
       <!-- User Sidebar -->
      <div class="user-sidebar">
  
    <div class="sidebar-inner">
      <ul class="navigation">
      <li ><a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a></li>
      <li><a href="/admin-dashboard-allemployer"><i class="la la-user-tie"></i>All Employers</a></li>
      <li class="active"><a href="/admin-managejob"><i class="la la-paper-plane"></i>Manage Job</a></li>
      <li><a href="/admin-add-job-category"><i class="la la-briefcase"></i> Add Job Category </a></li>
      <li><a href="/admin-add-employer-type"><i class="la la-file-invoice"></i> Add Employer Type</a></li>
      <li><a href="/admin-add-contracthire-type"><i class="la la-bookmark-o"></i>Direct Hire</a></li>
      <li><a href="/admin-add-contract-type"><i class="la la-bookmark-o"></i>Part Time Job</a></li>
      <li><a href="/admin-payment-status"><i class="la la-comment-o"></i>Payment Status</a></li>
      <li><a href="/admin-delete-profile"><i class="la la-trash"></i>Delete Profile</a></li>
      <!-- <li><a href="index.html"><i class="la la-trash"></i>Publish Ads</a></li> -->
      <li><a href="/admin-change-password"><i class="la la-lock"></i>Change Password</a></li>
      <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
      <li><a href="/login"><i class="la la-sign-out"></i>Logout</a></li>
            
          </ul>
        </div>
      </div>
  
      <!-- End User Sidebar -->
  
      <!--Page Title-->
    
      <!--End Page Title-->
  
      <!-- Dashboard -->
      <section class="user-dashboard">
        <div class="dashboard-outer">
          <div class="upper-title-box">
            <h3>Manage Jobs</h3>
            <div class="text">Ready to jump back in?</div>
          </div>
  
          <div class="row">
            <div class="col-lg-12">
              <!-- Ls widget -->
              <div class="ls-widget">
                <div class="tabs-box">
                  <div class="widget-title">
                    <h4>My Job Listings</h4>
  
                    <div class="chosen-outer">
                      <!--Tabs Box-->
                      <select class="chosen-select">
                        <option>Last 6 Months</option>
                        <option>Last 12 Months</option>
                        <option>Last 16 Months</option>
                        <option>Last 24 Months</option>
                        <option>Last 5 year</option>
                      </select>
                    </div>
                  </div>
  
                  <div class="widget-content">
                    <div class="table-outer">
                      <table class="default-table manage-job-table">
                        <thead>
                          <tr>
                            <th>University name</th>
                            <th>University code</th>
                            <th>Scholoarship open</th>
                            <th>Status</th>
                           
                          </tr>
                        </thead>
  
                        <tbody>
                          <tr  v-for="job in jobs" :key="job.id">
                            <td>
                              <h6>{{job.job_title}}</h6>
                              <span class="info"><i class="icon flaticon-map-locator"></i> London, UK</span>
                            </td>
                            <td class="applied"><a href="#">3+ Applied</a></td>
                            <td>October 27, 2017 <br>April 25, 2011</td>
               
                            <td>
                              <div class="option-box">
                                <ul class="option-list">
                                    
                                   
                                  <li  v-if="job.status=='approved'" ><button data-text="Approved"><span class="la la-check"></span></button></li>
                                  <li @click="approve(job.uuid)" v-else-if="job.status=='reviewing'" ><button data-text="Reviewing"><span class="las la-sync-alt"></span></button></li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
  
          </div>
        </div>
      </section>
      <!-- End Dashboard -->
  
  <!-- End Dashboard -->
  
  
  </div>
  </template>
  
  <script>
  import axios from 'axios'

  export default {
  
      name:'AdminManageJobView',

      data(){
      return{


        jobs:[],

        apr:"approved"
            


    }


   },




    methods:{

      


      getjob() { axios.get("app_admin/jobList/?page=2"
  
     ).then( res => {
  
    
       this.jobs=res.data.results
  
       if(res.status==200){
  
      console.log("Data received")
     
  
       }
     
     }
  
   )
  .catch(error => {
  console.log(error.response)
  });
   
  },

  approve(uuid){

 
    axios.put("app_admin/jobpost/update/"+uuid+"/",

    {
            status:this.apr
    
    },

     ).then( res => {
  
    
   
  
       if(res.status==200){
  
      console.log("Data received")
      this.getjob()
     
  
       }
     
     }
  
   )
  .catch(error => {
  console.log(error.response)
  });






  },

  review(uuid){

 
axios.put("app_admin/jobpost/update/"+uuid+"/",

{
        status:"reviewing"
      
       
},


 ).then( res => {




   if(res.status==200){

  console.log("Data received")
  this.getjob()
 

   }
 
 }

)
.catch(error => {
console.log(error.response)
});






}




    },

    mounted:function(){
        this.getjob()





    }



    

  }
  </script>
  
  <style>
  
  </style>