<template>
  <div >
      <!-- Sidebar Backdrop -->
      <div class="sidebar-backdrop"></div>

     <!-- User Sidebar -->
    <div class="user-sidebar">

      <div class="sidebar-inner">
        <ul class="navigation">
      <li class="active"><a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a></li>
      <li><a href="/admin-dashboard-allemployer"><i class="la la-user-tie"></i>All Employers</a></li>
      <li><a href="/admin-managejob"><i class="la la-paper-plane"></i>Manage Job</a></li>
      <li><a href="/admin-add-job-category"><i class="la la-briefcase"></i> Add Job Category </a></li>
      <li><a href="/admin-add-employer-type"><i class="la la-file-invoice"></i> Add Employer Type</a></li>
      <li><a href="/admin-add-contracthire-type"><i class="la la-bookmark-o"></i>Direct Hire</a></li>
      <li class="active"><a href="/admin-add-contract-type"><i class="la la-bookmark-o"></i>Part Time Job</a></li>
      <li><a href="/admin-payment-status"><i class="la la-comment-o"></i>Payment Status</a></li>
      <li><a href="/admin-delete-profile"><i class="la la-trash"></i>Delete Profile</a></li>
      <!-- <li><a href="index.html"><i class="la la-trash"></i>Publish Ads</a></li> -->
      <li><a href="/admin-change-password"><i class="la la-lock"></i>Change Password</a></li>
      <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
      <li><a href="/login"><i class="la la-sign-out"></i>Logout</a></li>
          
        </ul>
      </div>
    </div>

    <!-- End User Sidebar -->
    <!-- Mobile Header -->
      <div class="mobile-header">
        <div class="logo"><a href="index.html"><img src="images/logo.svg" alt="" title=""></a></div>

        <!--Nav Box-->
        <div class="nav-outer clearfix">

          <div class="outer-box">
            <!-- Login/Register -->
            <div class="login-box">
              <a href="login-popup.html" class="call-modal"><span class="icon-user"></span></a>
            </div>

            <a href="#nav-mobile" class="mobile-nav-toggler"><span class="flaticon-menu-1"></span></a>
          </div>
        </div>
      </div>

      <!-- Mobile Nav -->
      <div id="nav-mobile"></div>
    <!--Page Title-->
  
    <!--End Page Title-->

    <!-- Listing Section -->
      <!--Page Title-->
    <section class="page-title">
      <div class="auto-container">
        <div class="title-outer">
          <h1>Showing All Resumes</h1>
          <ul class="page-breadcrumb">
            <li><a href="/">Home</a></li>
            <li>Resumes</li>
          </ul>
        </div>
      </div>
    </section>
    <!--End Page Title-->

    <!-- Listing Section -->
    <section class="ls-section">
      <div class="auto-container">
        <div class="filters-backdrop"></div>
        <div class="row">
          <!-- Content Column -->
          <div v-for="part_time in part_time_job" :key="part_time.id" class="content-column col-lg-20 col-md-20 col-sm-20">
              <!-- Candidate block three -->
              <div class="candidate-block-three">
                <div class="inner-box" style="text-align: left;">
                  <div class="content">
                    <figure class="image"><img src="images/resource/candidate-1.png" alt=""></figure>
                    <h4 class="name"><a href="#">{{part_time.full_name}}</a></h4>
                    <ul class="candidate-info">
                      <!--li class="designation">UI Designer</li-->
                      <li><span class="icon flaticon-map-locator"></span>{{part_time.city}}</li>
                      <li><span class="icon flaticon-money"></span> $99 / hour</li>
                    </ul>
                    <ul class="post-tags">
                      <li v-for="s in part_time.skill[0].split(',')" :key="s" ><a  href="#">{{s}}</a></li>
                      <!--li><a href="#">{{part_time.skill[1]}}</a></li-->
                      <!--li><a href="#">{{part_time.skill[2]}}</a></li-->
                    </ul>
                  </div>
                  <div class="btn-box">
                    <!--button class="bookmark-btn"><span class="flaticon-bookmark"></span><button-->
                    <a href="#" class="theme-btn btn-style-three"><span class="btn-title">View Profile</span></a>
                  </div>
                </div>
              </div>

             
            
          </div>
           <!-- Listing Show More -->
           <div class="ls-show-more">
            <button class="show-more">Show More</button>
          </div>
        </div>
      </div>
    </section>
    <!--End Listing Page Section -->
 
    <!--End Listing Page Section -->
  </div>
</template>


<script>
import axios from "axios";
export default {

    name:'AdminContractApplicantView',
    data() {
    return {
      
     
      part_time_job: [],
      skills:[],
    };
  },


  methods:{

    get_part_time_job() {
      axios
        .get("app_admin/part_time_apply/",)
        .then((res) => {
          if (res.status == 200) {
            this.part_time_job= res.data.results;
          

            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

 mounted: function () {
  this.get_part_time_job();
  }
}
</script>

<style>

</style>