<template>
    <div>
      
  
     
  
      <!-- Dashboard -->
      <section @submit.prevent="add" class="user-dashboard">
        <div class="dashboard-outer">
          <div class="upper-title-box">
            <h3>Add Category</h3>
          </div>
  
          <div class="row">
            <div class="col-lg-12">
              <!-- Ls widget -->
              <div class="ls-widget">
                <div class="tabs-box p-3">
                  <div class="widget-content">
                    <form class="default-form">
                      <div class="row">
                        <!-- Input -->
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Category Name</label>
                          <input
                            type="text"
                            v-model="name"
                            placeholder="Category Name"
                            required
                          />
                        </div>
  
                   
                       
                 
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Parent Category</label>
                          <select v-model="category_id"  >
                            <option disabled  value="null">Select a category</option>
                            <option v-for="category in categories" :key="category.id" :value="category.id">{{category.name}}</option>
                          
                          </select>
                        </div>
  
                      
                     
  
                        <div class="form-group col-lg-4 col-md-12">
                          <label>Status</label>
                          <select  v-model="status">
                            <option  value="null">Select a option</option>
  
                            <option value="1">Enable</option>
                            <option value="0">Disable</option>
                          </select>
                        </div>
  
                        <div class="form-group col-lg-6 col-md-12">
                          <label>Icon</label>
                          <input class="form-control"
                            type="file"
                          
                            placeholder="Total program"
                            
                          />
                        </div>
                       
                        <!-- Input -->
                        <div class="form-group col-lg-12 col-md-12 text-right">
                          <button type="submit" class="theme-btn btn-style-one">
                            Add Category
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- End Dashboard -->
    
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
  
  export default {
  
    data() {
      return {
        categories: [],
        editor: ClassicEditor,
        category_id: null,

        status: null,
       
      };
    },
  
    methods: {

      category(){
    axios.get("api/admin/category").then((res) => {

      this.categories = res.data.data;
      
    }).catch((error) => {
      console.log(error.response);
    });

   },
   add(){
     axios.post("api/admin/category",{
       name:this.name,
       category_id:this.category_id,
       status:this.status
     }).then((res)=>{
       
      this.$emit("get_message", res.data.message);
       
     }).catch((error)=>{
       console.log(error.response)
     })
   }
   
 
  
    
     
    },
    mounted: function () {
      this.category();

    },
  };
  </script>
  
  
  
  <style>
  .button_plus {
    position: absolute;
    width: 35px;
    height: 35px;
    background: #fff;
    cursor: pointer;
    border: 2px solid #095776;
    display: flex;
  
    /* Mittig */
    top: 44%;
    left: 90%;
  }
  
  .button_plus:after {
    content: "";
    position: absolute;
    transform: translate(-50%, -50%);
    height: 4px;
    width: 50%;
    background: #095776;
    top: 50%;
    left: 50%;
  }
  
  .button_plus:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #095776;
    height: 50%;
    width: 4px;
  }
  
  .button_plus:hover:before,
  .button_plus:hover:after {
    background: #fff;
    transition: 0.2s;
  }
  
  .button_plus:hover {
    background-color: #095776;
    transition: 0.2s;
  }
  </style>