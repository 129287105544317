<template>
   <!--Page Title-->
    <div>
   <br><br><br>
   <section class="checkout-page">
      <div class="auto-container">
        <div class="row">

          <div class="column col-lg-8 col-md-12 col-sm-12">
            <!--Checkout Details-->
            <div class="checkout-form">
              <h3 class="title">Billing Details</h3>
              <form method="post" action="checkout.html" class="default-form">
                <div class="row">
                  <!--Form Group-->
                  <div class="form-group col-lg-6 col-md-12 col-sm-12">
                    <div class="field-label">First name <sup>*</sup></div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>

                  <!--Form Group-->
                  <div class="form-group col-lg-6 col-md-12 col-sm-12">
                    <div class="field-label">Last name <sup>*</sup></div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>

                  <!--Form Group-->
                  <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <div class="field-label">Company name (optional)</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>

                  <!--Form Group-->
                  <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <div class="field-label">Country <sup>*</sup></div>
                    <select name="billing_country" class="select2 sortby-select" autocomplete="country">
                      <option value="">Select a country&hellip;</option>
                      <option value="AX">&#197;land Islands</option>
                      <option value="AF">Afghanistan</option>
                      <option value="AL">Albania</option>
                      <option value="DZ">Algeria</option>
                      <option value="AS">American Samoa</option>
                      <option value="AD">Andorra</option>
                      <option value="AO">Angola</option>
                      <option value="AI">Anguilla</option>
                      <option value="AQ">Antarctica</option>
                      <option value="AG">Antigua and Barbuda</option>
                      <option value="AR">Argentina</option>
                      <option value="AM">Armenia</option>
                      <option value="AW">Aruba</option>
                      <option value="AU">Australia</option>
                      <option value="AT">Austria</option>
                      <option value="AZ">Azerbaijan</option>
                      <option value="BS">Bahamas</option>
                      <option value="BH">Bahrain</option>
                      <option value="BD">Bangladesh</option>
                      <option value="BB">Barbados</option>
                      <option value="BY">Belarus</option>
                      <option value="PW">Belau</option>
                      <option value="BE">Belgium</option>
                      <option value="BZ">Belize</option>
                      <option value="BJ">Benin</option>
                      <option value="BM">Bermuda</option>
                      <option value="BT">Bhutan</option>
                      <option value="BO">Bolivia</option>
                      <option value="BQ">Bonaire, Saint Eustatius and Saba</option>
                      <option value="BA">Bosnia and Herzegovina</option>
                      <option value="BW">Botswana</option>
                      <option value="BV">Bouvet Island</option>
                      <option value="BR">Brazil</option>
                      <option value="IO">British Indian Ocean Territory</option>
                      <option value="VG">British Virgin Islands</option>
                      <option value="BN">Brunei</option>
                      <option value="BG">Bulgaria</option>
                      <option value="BF">Burkina Faso</option>
                      <option value="BI">Burundi</option>
                      <option value="KH">Cambodia</option>
                      <option value="CM">Cameroon</option>
                      <option value="CA">Canada</option>
                      <option value="CV">Cape Verde</option>
                      <option value="KY">Cayman Islands</option>
                      <option value="CF">Central African Republic</option>
                      <option value="TD">Chad</option>
                      <option value="CL">Chile</option>
                      <option value="CN">China</option>
                      <option value="CX">Christmas Island</option>
                      <option value="CC">Cocos (Keeling) Islands</option>
                      <option value="CO">Colombia</option>
                      <option value="KM">Comoros</option>
                      <option value="CG">Congo (Brazzaville)</option>
                      <option value="CD">Congo (Kinshasa)</option>
                      <option value="CK">Cook Islands</option>
                      <option value="CR">Costa Rica</option>
                      <option value="HR">Croatia</option>
                      <option value="CU">Cuba</option>
                      <option value="CW">Cura&ccedil;ao</option>
                      <option value="CY">Cyprus</option>
                      <option value="CZ">Czech Republic</option>
                      <option value="DK">Denmark</option>
                      <option value="DJ">Djibouti</option>
                      <option value="DM">Dominica</option>
                      <option value="DO">Dominican Republic</option>
                      <option value="EC">Ecuador</option>
                      <option value="EG">Egypt</option>
                      <option value="SV">El Salvador</option>
                      <option value="GQ">Equatorial Guinea</option>
                      <option value="ER">Eritrea</option>
                      <option value="EE">Estonia</option>
                      <option value="ET">Ethiopia</option>
                      <option value="FK">Falkland Islands</option>
                      <option value="FO">Faroe Islands</option>
                      <option value="FJ">Fiji</option>
                      <option value="FI">Finland</option>
                      <option value="FR">France</option>
                      <option value="GF">French Guiana</option>
                      <option value="PF">French Polynesia</option>
                      <option value="TF">French Southern Territories</option>
                      <option value="GA">Gabon</option>
                      <option value="GM">Gambia</option>
                      <option value="GE">Georgia</option>
                      <option value="DE">Germany</option>
                      <option value="GH">Ghana</option>
                      <option value="GI">Gibraltar</option>
                      <option value="GR">Greece</option>
                      <option value="GL">Greenland</option>
                      <option value="GD">Grenada</option>
                      <option value="GP">Guadeloupe</option>
                      <option value="GU">Guam</option>
                      <option value="GT">Guatemala</option>
                      <option value="GG">Guernsey</option>
                      <option value="GN">Guinea</option>
                      <option value="GW">Guinea-Bissau</option>
                      <option value="GY">Guyana</option>
                      <option value="HT">Haiti</option>
                      <option value="HM">Heard Island and McDonald Islands</option>
                      <option value="HN">Honduras</option>
                      <option value="HK">Hong Kong</option>
                      <option value="HU">Hungary</option>
                      <option value="IS">Iceland</option>
                      <option value="IN">India</option>
                      <option value="ID">Indonesia</option>
                      <option value="IR">Iran</option>
                      <option value="IQ">Iraq</option>
                      <option value="IE">Ireland</option>
                      <option value="IM">Isle of Man</option>
                      <option value="IL">Israel</option>
                      <option value="IT">Italy</option>
                      <option value="CI">Ivory Coast</option>
                      <option value="JM">Jamaica</option>
                      <option value="JP">Japan</option>
                      <option value="JE">Jersey</option>
                      <option value="JO">Jordan</option>
                      <option value="KZ">Kazakhstan</option>
                      <option value="KE">Kenya</option>
                      <option value="KI">Kiribati</option>
                      <option value="KW">Kuwait</option>
                      <option value="KG">Kyrgyzstan</option>
                      <option value="LA">Laos</option>
                      <option value="LV">Latvia</option>
                      <option value="LB">Lebanon</option>
                      <option value="LS">Lesotho</option>
                      <option value="LR">Liberia</option>
                      <option value="LY">Libya</option>
                      <option value="LI">Liechtenstein</option>
                      <option value="LT">Lithuania</option>
                      <option value="LU">Luxembourg</option>
                      <option value="MO">Macao S.A.R., China</option>
                      <option value="MK">Macedonia</option>
                      <option value="MG">Madagascar</option>
                      <option value="MW">Malawi</option>
                      <option value="MY">Malaysia</option>
                      <option value="MV">Maldives</option>
                      <option value="ML">Mali</option>
                      <option value="MT">Malta</option>
                      <option value="MH">Marshall Islands</option>
                      <option value="MQ">Martinique</option>
                      <option value="MR">Mauritania</option>
                      <option value="MU">Mauritius</option>
                      <option value="YT">Mayotte</option>
                      <option value="MX">Mexico</option>
                      <option value="FM">Micronesia</option>
                      <option value="MD">Moldova</option>
                      <option value="MC">Monaco</option>
                      <option value="MN">Mongolia</option>
                      <option value="ME">Montenegro</option>
                      <option value="MS">Montserrat</option>
                      <option value="MA">Morocco</option>
                      <option value="MZ">Mozambique</option>
                      <option value="MM">Myanmar</option>
                      <option value="NA">Namibia</option>
                      <option value="NR">Nauru</option>
                      <option value="NP">Nepal</option>
                      <option value="NL">Netherlands</option>
                      <option value="NC">New Caledonia</option>
                      <option value="NZ">New Zealand</option>
                      <option value="NI">Nicaragua</option>
                      <option value="NE">Niger</option>
                      <option value="NG">Nigeria</option>
                      <option value="NU">Niue</option>
                      <option value="NF">Norfolk Island</option>
                      <option value="KP">North Korea</option>
                      <option value="MP">Northern Mariana Islands</option>
                      <option value="NO">Norway</option>
                      <option value="OM">Oman</option>
                      <option value="PK" >Pakistan</option>
                      <option value="PS">Palestinian Territory</option>
                      <option value="PA">Panama</option>
                      <option value="PG">Papua New Guinea</option>
                      <option value="PY">Paraguay</option>
                      <option value="PE">Peru</option>
                      <option value="PH">Philippines</option>
                      <option value="PN">Pitcairn</option>
                      <option value="PL">Poland</option>
                      <option value="PT">Portugal</option>
                      <option value="PR">Puerto Rico</option>
                      <option value="QA">Qatar</option>
                      <option value="RE">Reunion</option>
                      <option value="RO">Romania</option>
                      <option value="RU">Russia</option>
                      <option value="RW">Rwanda</option>
                      <option value="ST">S&atilde;o Tom&eacute; and Pr&iacute;ncipe</option>
                      <option value="BL">Saint Barth&eacute;lemy</option>
                      <option value="SH">Saint Helena</option>
                      <option value="KN">Saint Kitts and Nevis</option>
                      <option value="LC">Saint Lucia</option>
                      <option value="SX">Saint Martin (Dutch part)</option>
                      <option value="MF">Saint Martin (French part)</option>
                      <option value="PM">Saint Pierre and Miquelon</option>
                      <option value="VC">Saint Vincent and the Grenadines</option>
                      <option value="WS">Samoa</option>
                      <option value="SM">San Marino</option>
                      <option value="SA">Saudi Arabia</option>
                      <option value="SN">Senegal</option>
                      <option value="RS">Serbia</option>
                      <option value="SC">Seychelles</option>
                      <option value="SL">Sierra Leone</option>
                      <option value="SG">Singapore</option>
                      <option value="SK">Slovakia</option>
                      <option value="SI">Slovenia</option>
                      <option value="SB">Solomon Islands</option>
                      <option value="SO">Somalia</option>
                      <option value="ZA">South Africa</option>
                      <option value="GS">South Georgia/Sandwich Islands</option>
                      <option value="KR">South Korea</option>
                      <option value="SS">South Sudan</option>
                      <option value="ES">Spain</option>
                      <option value="LK">Sri Lanka</option>
                      <option value="SD">Sudan</option>
                      <option value="SR">Suriname</option>
                      <option value="SJ">Svalbard and Jan Mayen</option>
                      <option value="SZ">Swaziland</option>
                      <option value="SE">Sweden</option>
                      <option value="CH">Switzerland</option>
                      <option value="SY">Syria</option>
                      <option value="TW">Taiwan</option>
                      <option value="TJ">Tajikistan</option>
                      <option value="TZ">Tanzania</option>
                      <option value="TH">Thailand</option>
                      <option value="TL">Timor-Leste</option>
                      <option value="TG">Togo</option>
                      <option value="TK">Tokelau</option>
                      <option value="TO">Tonga</option>
                      <option value="TT">Trinidad and Tobago</option>
                      <option value="TN">Tunisia</option>
                      <option value="TR">Turkey</option>
                      <option value="TM">Turkmenistan</option>
                      <option value="TC">Turks and Caicos Islands</option>
                      <option value="TV">Tuvalu</option>
                      <option value="UG">Uganda</option>
                      <option value="UA">Ukraine</option>
                      <option value="AE">United Arab Emirates</option>
                      <option value="GB" selected='selected'>United Kingdom (UK)</option>
                      <option value="US">United States (US)</option>
                      <option value="UM">United States (US) Minor Outlying Islands</option>
                      <option value="VI">United States (US) Virgin Islands</option>
                      <option value="UY">Uruguay</option>
                      <option value="UZ">Uzbekistan</option>
                      <option value="VU">Vanuatu</option>
                      <option value="VA">Vatican</option>
                      <option value="VE">Venezuela</option>
                      <option value="VN">Vietnam</option>
                      <option value="WF">Wallis and Futuna</option>
                      <option value="EH">Western Sahara</option>
                      <option value="YE">Yemen</option>
                      <option value="ZM">Zambia</option>
                      <option value="ZW">Zimbabwe</option>
                    </select>
                  </div>

                  <!--Form Group-->
                  <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <div class="field-label">Street address <sup>*</sup></div>
                    <input type="text" name="field-name" value="" placeholder="House number and street name">
                    <input type="text" name="field-name" value="" placeholder="Apartment,suite,unit etc. (optional)">
                  </div>

                  <!--Form Group-->
                  <div class="form-group col-lg-6 col-md-12 col-sm-12">
                    <div class="field-label">Town / City <sup>*</sup></div>
                    <input type="text" name="field-name" value="" placeholder="" required="">
                  </div>

                  <!--Form Group-->
                  <div class="form-group col-lg-6 col-md-12 col-sm-12">
                    <div class="field-label">State / County <sup>*</sup></div>
                    <input type="text" name="field-name" value="" placeholder="" required="">
                  </div>

                  <!--Form Group-->
                  <div class="form-group col-lg-6 col-md-12 col-sm-12">
                    <div class="field-label">Postcode/ ZIP <sup>*</sup></div>
                    <input type="text" name="field-name" value="" placeholder="" required="">
                  </div>

                  <!--Form Group-->
                  <div class="form-group col-lg-6 col-md-12 col-sm-12">
                    <div class="field-label">Phone</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>

                  <!--Form Group-->
                  <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <div class="field-label">Email Address</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>

                  <!--Form Group-->
                  <div class="form-group col-lg-12 col-md-12 col-sm-12">
                    <h3 class="title">Additional information</h3>
                    <div class="field-label">Order notes (optional)</div>
                    <textarea class="" placeholder="Notes about your order, e.g. special notes for delivery."></textarea>
                  </div>
                </div>
              </form>
            </div>
            <!--End Checkout Details-->
          </div>

          <div class="column col-lg-4 col-md-12 col-sm-12">
            <!--Order Box-->
            <div class="order-box">
              <h3>Your Order</h3>
              <table>
                <thead>
                  <tr>
                    <th><strong>Product</strong></th>
                    <th><strong>Subtotal</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="cart-item">
                    <td class="product-name">Hoodie x2</td>
                    <td class="product-total">59.00</td>
                  </tr>

                  <tr class="cart-item">
                    <td class="product-name">Seo Books x 1</td>
                    <td class="product-total">67.00</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="cart-subtotal">
                    <td>Subtotal</td>
                    <td><span class="amount">$178.00</span></td>
                  </tr>
                  <tr class="cart-subtotal">
                    <td>Shipping</td>
                    <td><span class="amount">$178.00</span></td>
                  </tr>
                  <tr class="order-total">
                    <td>Total</td>
                    <td><span class="amount">$9,218.00</span></td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <!--End Order Box-->

            <!--Payment Box-->
            <div class="payment-box">
              <!--Payment Options-->
              <div class="payment-options">
                <ul>
                  <li>
                    <div class="radio-option radio-box">
                      <input type="radio" name="payment-group" id="payment-2" checked>
                      <label for="payment-2">Direct Bank Transfer<span class="small-text">Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.</span></label>
                    </div>
                  </li>

                  <li>
                    <div class="radio-option radio-box">
                      <input type="radio" name="payment-group" id="payment-1">
                      <label for="payment-1">Check Payments<span class="small-text">Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.</span></label>
                    </div>
                  </li>

                  <li>
                    <div class="radio-option radio-box">
                      <input type="radio" name="payment-group" id="payment-3">
                      <label for="payment-3">Cash on Delivery<span class="small-text">Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.</span></label>
                    </div>
                  </li>

                  <li>
                    <div class="radio-option radio-box">
                      <input type="radio" name="payment-group" id="payment-4">
                      <label for="payment-4"><strong>PayPal</strong>
                        <img src="images/icons/paypal.png" alt="">
                      </label>
                    </div>
                  </li>
                </ul>

                <div class="btn-box">
                  <a href="#" class="theme-btn btn-style-one">Place Order</a>
                </div>
              </div>

            </div>
            <!--End Payment Box-->
          </div>
        </div>
      </div>
    </section>
    <!--End CheckOut Page-->
  </div>
</template>

<script>
export default {
  name:'ShopingCartView',
  data() {
        return {
            
            
        };

        
            
        


    }


}
</script>

<style>

</style>