import axios from 'axios';
axios.defaults.baseURL = 'https://api.pandaxue.com/public/';
// axios.defaults.baseURL = 'http://pandaxuelaravel.com/';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*' ;
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT';
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// axios.defaults.xsrfCookieName = "csrftoken";


// import vcookie from 'vue-cookie';

  
// axios.interceptors.request.use(
//   function(config) {
   


//   if(config.url.includes("public") || config.url.includes("login") || config.url.includes("logout")){

   

//   config.headers.Authorization = ''



//   }else{
//     const token = JSON.parse(vcookie.get('user')).user.token;
    



//       if(token){
       
//       config.headers.Authorization = `Bearer ${token}`

//       }

    

//   }
      

//     return config;
//   },
//   function(error) {
//    alert(error.response)
//     return Promise.reject(error);
//   }
// );








