<template>
    
    <div>
         <!-- Sidebar Backdrop -->
   <div class="sidebar-backdrop"></div>
  
  <!-- User Sidebar -->
  <div class="user-sidebar">
        <div class="sidebar-inner">
          <ul class="navigation">
            <li>
              <a href="/employee-dashboard">
                <i class="la la-home"></i> Dashboard</a
              >
            </li>
            <li  >
              <a href="/employer-managejob"
                ><i class="la la-user-tie"></i>All University</a
              >
            </li>
            <li >
              <a href="/employer-postjob"
                ><i class="la la-paper-plane"></i>Add University</a
              >
            </li>
            <li class="active">
            <a href="/employer-manage-category"
              ><i class="la la-briefcase"></i> Category
            </a>
          </li>
            <li>
              <a href="/employer-managejob"
                ><i class="la la-briefcase"></i> Department
              </a>
            </li>
            <li>
              <a href="/employer-managejob"
                ><i class="la la-briefcase"></i> Program
              </a>
            </li>
            <!-- <li>
              <a href="/employer-allapplicants"
                ><i class="la la-file-invoice"></i> All Applicants</a
              >
            </li> -->
            <li>
              <a href="#"
                ><i class="la la-bookmark-o"></i>Contractual Applicants</a
              >
            </li>
            <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
            <li>
              <a href="#"><i class="la la-trash"></i>Delete Profile</a>
            </li>
            <li>
              <a href="#"><i class="la la-lock"></i>Change Password</a>
            </li>
  
            <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
            <li>
              <a @click.prevent="logout"><i class="la la-sign-out"></i>Logout</a>
            </li>
          </ul>
        </div>
      </div>
  <!-- End User Sidebar -->
  
   <!-- Dashboard -->
      <section class="user-dashboard">
        <div class="dashboard-outer">
          <div class="upper-title-box">
            <h3>Manage University category </h3>
        
          </div>
  
          <div class="row">
            <div class="col-lg-12">
              <!-- Ls widget -->
              <div class="ls-widget">
                <div class="tabs-box">
                  <div class="widget-title">
                    <h4 class="float-left" >Category  List</h4>
                    <a href="/employer-add-category" class="btn btn-primary float-right" >Add</a>


  
                    
                  </div>
  
                  <div class="widget-content">
                    <div class="table-outer">
                      <table class="default-table manage-job-table">
                        <thead>
                          <tr>
                            <th>Category  name</th>
                              <th>Category code</th>
                            
                              <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
  
                        <tbody>
                          <tr   >
                            <td>
                              <h6>Public</h6>
                             
                            </td>
  
                            <td>e2324e3f</td>
                           
                            <td class="status">Open</td>
                            <td>
                              <div class="option-box">
                                <ul class="option-list">
                                  <li><button data-text="Reject Aplication"><span class="la la-pencil"></span></button></li>
                                  <li><button data-text="Delete Aplication"><span class="la la-trash"></span></button></li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                          <tr   >
                            <td>
                              <h6>Private</h6>
                             
                            </td>
  
                            <td>e2324e3f</td>
                           
                            <td class="status">Open</td>
                            <td>
                              <div class="option-box">
                                <ul class="option-list">
                                  <li><button data-text="Reject Aplication"><span class="la la-pencil"></span></button></li>
                                  <li><button data-text="Delete Aplication"><span class="la la-trash"></span></button></li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                         
                         
                          <tr   >
                            <td>
                              <h6>Technology</h6>
                             
                            </td>
  
                            <td>e2324e3f</td>
                           
                            <td class="status">Open</td>
                            <td>
                              <div class="option-box">
                                <ul class="option-list">
                                  <li><button data-text="Reject Aplication"><span class="la la-pencil"></span></button></li>
                                  <li><button data-text="Delete Aplication"><span class="la la-trash"></span></button></li>
                                </ul>
                              </div>
                            </td>
                          </tr>

                         
                       
                       
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
  
          </div>
        </div>
      </section>
      <!-- End Dashboard -->
  
  <!-- End Dashboard -->
  
  
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
  
      name:'EmployerManageJobView',
  
      data(){
        return{
  
         universities:[],
  
        }
  
      },
  
  
  methods: {
    
  
    getjob() { axios.get("api/universities"
  
     ).then( res => {
  
    
       this.universities=res.data.data
  
       if(res.status==200){
  
        console.log("Data received")
     
  
       }
     
     }
  
   )
  .catch(error => {
  console.log(error.response)
  });
   
  },
  },
  
  mounted:function(){
    this.getjob()
  }
  
    
  }
  </script>
  
  <style>
  
  </style>