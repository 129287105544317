<template>
<admin-master-view v-if="this.$route.meta.layout==='admin'" />
<master-view  v-else-if="this.$route.meta.layout==null"  />
<auth-master-view  v-else-if="this.$route.meta.layout=='auth'"  />
<Student-master-view  v-else-if="this.$route.meta.layout=='student'"  />


  
</template>

<script>
import AdminMasterView from './views/master/AdminMasterView.vue'
import StudentMasterView from './views/master/StudentMasterView.vue'
import MasterView from './views/master/MasterView'
import AuthMasterView from './views/master/AuthMasterView'

export default {
   components:{AdminMasterView,MasterView,AuthMasterView,StudentMasterView},
}
</script>

<style>

</style>