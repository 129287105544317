<template>
    <div>
    <!-- Main Header-->
    <header class="main-header header-shaddow">
      <div class="container-fluid">
        <!-- Main box -->
        <div class="main-box">
          <!--Nav Outer -->
          <div class="nav-outer">
            <div class="logo-box">
              <div class="logo"><a href="index.html"><img src="images/logo.png" alt="" title=""></a></div>
            </div>

            <nav class="nav main-menu">
              <ul class="navigation" id="navbar">
               
              </ul>
            </nav>
            <!-- Main Menu End-->
          </div>

          <div class="outer-box">

            <button class="menu-btn">
              <span class="count">1</span>
              <span class="icon la la-heart-o"></span>
            </button>

            <button class="menu-btn">
              <span class="icon la la-bell"></span>
            </button>

            <!-- Dashboard Option -->
            <div class="dropdown dashboard-option">
              <a class="dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                <img src="images/resource/company-6.png" alt="avatar" class="thumb">
                <span class="name">My Account</span>
              </a>
              <ul class="dropdown-menu">
                <li class="active"><a href="/candidate-dashboard"> <i class="la la-home"></i> Dashboard</a></li>
                <li><a href="dashboard-change-password.html"><i class="la la-lock"></i>Change Password</a></li>
                <li><a href="index.html"><i class="la la-sign-out"></i>Logout</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Mobile Header -->
      <div class="mobile-header">
        <div class="logo"><a href="index.html"><img src="images/logo.png" alt="" title=""></a></div>

        <!--Nav Box-->
        <div class="nav-outer clearfix">

          <div class="outer-box">
            <!-- Login/Register -->
            <div class="login-box">
              <a href="login-popup.html" class="call-modal"><span class="icon-user"></span></a>
            </div>

            <button id="toggle-user-sidebar"><img src="images/resource/company-6.png" alt="avatar" class="thumb"></button>
            <a href="#nav-mobile" class="mobile-nav-toggler navbar-trigger"><span class="flaticon-menu-1"></span></a>
          </div>
        </div>

      </div>

      <!-- Mobile Nav -->
      <div id="nav-mobile"></div>
    </header>
    <!--End Main Header -->

    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">

      <div class="sidebar-inner">
        <ul class="navigation">
            <li><a href="/candidate-dashboard"> <i class="la la-home"></i> Dashboard</a></li>
                <li><a href="/candidate-profile"><i class="la la-user-tie"></i>My Resume</a></li>
                <!--li><a href="/candidate-resume"><i class="la la-file-invoice"></i>My Resume</a></li-->
                <!--li><a href="/candidate-CvManager"><i class="la la-file-invoice"></i>Cv Manager</a></li-->
                <li><a href="/candidate-findjob"><i class="la la-user-alt"></i>Find jobs</a></li>
                <li class="active"><a href="/candidate-appliedjob"><i class="la la-briefcase"></i> Applied Jobs </a></li>
                <li><a href="/candidate-change-password"><i class="la la-lock"></i>Change Password</a></li>
                <li><a href="#"><i class="la la-sign-out"></i>Logout</a></li>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

     <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Applied Jobs</h3>
          <div class="text">Ready to jump back in?</div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <h4>My Applied Jobs</h4>

                  <div class="chosen-outer">
                    <!--Tabs Box-->
                    <select class="chosen-select">
                      <option>Last 6 Months</option>
                      <option>Last 12 Months</option>
                      <option>Last 16 Months</option>
                      <option>Last 24 Months</option>
                      <option>Last 5 year</option>
                    </select>
                  </div>
                </div>

                <div class="widget-content">
                  <div class="table-outer">
                    <table class="default-table manage-job-table">
                      <thead>
                        <tr>
                          <th>Job Title</th>
                          <th>Date Applied</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>
                            <!-- Job Block -->
                            <div v-for="job in  applied_job" :key="job.id"  class="job-block">
                              <div @click="openjob(job.uuid)" class="inner-box">
                                <div class="content">
                                  <span class="company-logo"><img src="images/resource/company-logo/1-1.png" alt=""></span>
                                  <h4><a href="#">{{job.job_post.job_title}}</a></h4>
                                  <ul class="job-info">
                                    <li><span class="icon flaticon-briefcase"></span> Segment</li>
                                    <li><span class="icon flaticon-map-locator"></span> London, UK</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>Dec 5, 2020</td>
                          <td class="status">Active</td>
                          <td>
                            <div class="option-box">
                              <ul class="option-list">
                                <li><button data-text="View Aplication"><span class="la la-eye"></span></button></li>
                                <li><button data-text="Delete Aplication"><span class="la la-trash"></span></button></li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                        

                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
    <!--End Listing Page Section -->

    </div>
</template>

<script>

import axios from 'axios'
export default {

    name:'CandidateAppliedJobsView',

    data() {
        return {
          applied_job:[],
                        
        };
    },

methods: {
     

applied_jobs() { axios.get("applicant/job_application/",).then( res => {


if(res.status==200){
  this.applied_job=res.data.results

  console.log("ok")
   }
}
)
.catch(error => {
console.log(error.response)
});
},


openjob(uuid){



  if(uuid!=null){

      this.$router.push({  name: 'job-single', query: { uuid: uuid } });

     
  }
     
}


},
  
mounted: function(){
   
   this.applied_jobs()
    
}  
}
</script>

<style>

</style>