<template>
  <div >
      <!-- Sidebar Backdrop -->
      <div class="sidebar-backdrop"></div>

     <!-- User Sidebar -->
    <div class="user-sidebar">

      <div class="sidebar-inner">
        <ul class="navigation">
      <li class="active"><a href="/admin-dashboard"> <i class="la la-home"></i> Dashboard</a></li>
      <li><a href="/admin-dashboard-allemployer"><i class="la la-user-tie"></i>All Employers</a></li>
      <li><a href="/admin-managejob"><i class="la la-paper-plane"></i>Manage Job</a></li>
      <li class="active"><a href="/admin-add-job-category"><i class="la la-briefcase"></i> Add Job Category </a></li>
      <li><a href="/admin-add-employer-type"><i class="la la-file-invoice"></i> Add Employer Type</a></li>
      <li><a href="/admin-add-contracthire-type"><i class="la la-bookmark-o"></i>Direct Hire</a></li>
      <li><a href="/admin-add-contract-type"><i class="la la-bookmark-o"></i>Part Time Job</a></li>
      <li><a href="/admin-payment-status"><i class="la la-comment-o"></i>Payment Status</a></li>
      <li><a href="/admin-delete-profile"><i class="la la-trash"></i>Delete Profile</a></li>
      <!-- <li><a href="index.html"><i class="la la-trash"></i>Publish Ads</a></li> -->
      <li><a href="/admin-change-password"><i class="la la-lock"></i>Change Password</a></li>
      <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
      <li><a href="/login"><i class="la la-sign-out"></i>Logout</a></li>
          
        </ul>
      </div>
    </div>

    <!-- End User Sidebar -->

    <!--Page Title-->
  
    <!--End Page Title-->

    <!-- Listing Section -->
   <section class="page-title style-two">
      <div class="auto-container">
         <div class="title-outer">
          <h1>Add Job Category</h1>
          <ul class="page-breadcrumb">
            <li><a href="/">Dashboard</a></li>
            <li>Add Job Category</li>
          </ul>
        </div>
        <!-- <div class="title-outer">
                <h1>Find Jobs</h1>
                <ul class="page-breadcrumb">
                    <li><a href="index.html">Home</a></li>
                    <li>Jobs</li>
                </ul> 
            </div> -->

         <!-- Contact Form -->
        <div class="contact-form default-form">
          <br> <br>
          
          <!--Contact Form-->
          <form @submit.prevent="addJobCategory">
            <div class="row">
              <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <div class="response"></div>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                <!--label>Your Name</label-->
                <input type="text" v-model="code" class="username" placeholder="Job Category Code*" required>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12 form-group">
                <!--label>Your Email</label-->
                <input type="text" v-model="cat_name" class="username" placeholder="Category Name*" required>
              </div>

              <!--div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <label>Subject</label>
                <input type="text" name="subject" class="subject" placeholder="Subject *" required>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 form-group">username
                <label>Your Message</label>
                <textarea name="message" placeholder="Write your message..." required=""></textarea>
              </div-->
              <br><br>
              <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                <button class="theme-btn btn-style-one" type="submit" id="submit" name="submit-form">Submit Category</button>
              </div>
            </div>
          </form>
          <br>
          <br>
          <div class="table-outer">
              <table class="default-table invoice-table">
                <thead>
                  <tr>
                    <th>Employer Type Code</th>
                    <th>Category Name</th>
                    <th>Created On</th>
                    <th>Updated On</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="type in jobType" :key="type.id">
                    <td>{{type.code}}</td>
                    <td>{{type.cat_name}} </td>
                    <td>{{ format_date(type.created) }}</td>
                    <td>{{ format_date(type.last_updated) }}</td>
                  </tr>
               
                  
                </tbody>
              </table>
            </div>
        </div>
        <!--End Contact Form -->
           <!--div-->
              <!-- Pagination -->
              <!--nav class="ls-pagination">
                <ul>
                  <li class="prev"><a href="#"><i class="fa fa-arrow-left"></i></a></li>
                  <li><a href="#">1</a></li>
                  <li><a href="#" class="current-page">2</a></li>
                  <li><a href="#">3</a></li>
                  <li class="next"><a href="#"><i class="fa fa-arrow-right"></i></a></li>
                </ul>
              </nav>
            </div-->
          </div>
        
      
    </section>
 
    <!--End Listing Page Section -->
  </div>
</template>




<script>
import axios from 'axios';
import moment from 'moment';


export default {
        name:'AdminAddJobCategoryView',
        data() {
            return {
              jobType:[],
            };
        },
        methods:{
            addJobCategory(){
                axios.post("app_admin/job-category/",
                    {
                      code:this.code,
                      cat_name:this.cat_name,          
                    }
                  ).then( res => {
                    this.getJobType()
                    if(res.status==201){
                        console.log(res.data)
                    }
                  }
                )
              .catch(error => {
                  console.log(error.response)
              });
            },

            getJobType(){
                axios.get("app_admin/job-category/",
                    ).then( res => {
                      if(res.status==201 || res.status==200){
                        this.jobType=res.data
                        console.log(res.status)
                      }
                    }
                  )
                  .catch(error => {
                   
                    console.log(error.response)
                  }
                );
             },

              format_date(value){
                      if (value) {
                        return moment(String(value)).format('DD/MM/YYYY hh:mm:ss')
                      }
                },
        },
      mounted: function(){
      this.getJobType()
      }
  }
</script>

<style>

</style>