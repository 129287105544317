<template>
  <div>
    <!-- <div id="popbg">f</div> -->

    <div id="homepopup" style="display: none">
      <i @click="pophide" class="fa-solid fa-xmark popicon"></i>
      <div
        style="height: 100%"
        id="carouselExampleSlidesOnly"
        class="carousel"
        data-ride="carousel"
      >
        <div style="height: 100%" class="carousel-inner">
          <div
            style="object-fit: cover"
            v-for="popupad in popupads"
            :key="popupad.id"
            class="carousel-item active bgp2"
            v-bind:class="condition ? 'class_if_is_true' : 'else_class'"
          >
            <img
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              :src="popupad.background"
            />
          </div>
        </div>
      </div>
    </div>

    <div id="shortside" class="d-none">
      <div style="height: 50%">
        <div class="shortbtn1 text-center pt-2">
          <a href="" style="color: white">PART TIME JOBS</a>
        </div>
      </div>
      <div style="height: 50%">
        <div style="height: 50%">
          <div class="shortbtn2 text-center pt-2">
            <a href="/" style="color: black">DIRECT HIRING</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Banner Section-->
    <section class="banner-section">
      <!-- <div id="mySidenav" class="sidenav">
          <a href="/parttimejob" id="about">Part-time Job</a>
          <a href="#" id="blog">Direct Hire</a>
          <a href="#" id="projects">Summer Job</a>
          <a href="#" id="contact">Feature Job</a>
    </div> -->
      <div class="auto-container">
        <div class="row">
          <div class="content-column col-lg-7 col-md-12 col-sm-12">
            <div class="inner-column wow fadeInUp" data-wow-delay="1000ms">
              <div class="title-box">
                <h3>Study In China With 100% Scholarships</h3>
                <h5 class="text-small-white">
                  Find offers, Scholarships & Universities
                </h5>
              </div>

              <!-- Job Search Form -->
              <div class="job-search-form">
                <form>
                  <div class="row">
                    <div class="form-group col-lg-6 col-md-12 col-sm-12">
                      <span class="icon flaticon-search-1"></span>
                      <input
                        type="text"
                        class="font110"
                        v-model="search_key"
                        placeholder=" Title, keywords, or universities"
                      />
                    </div>
                    <!-- Form Group -->
                    <div
                      class="form-group col-lg-3 col-md-12 col-sm-12 location"
                    >
                      <span class="icon flaticon-map-locator"></span>
                      <input type="text" name="field_name" placeholder="City" />
                    </div>
                    <!-- Form Group -->
                    <div
                      class="form-group col-lg-3 col-md-12 col-sm-12 btn-box"
                    >
                      <button
                        type="submit"
                        class="btn-style-one theme-color-bg2"
                      >
                        <span class="btn-title"> FIND </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <!-- Job Search Form -->

              <!-- Popular Search -->
              <div class="text-white font140">Popular Searches :</div>
              <div class="mt-3 mb-5" style="cursor: pointer">
                <button class="theme-button3 font120 text-white">
                  <span class="icon flaticon-search-1"></span> offer
                </button>
                <button class="theme-button3 font120 text-white" href="#">
                  <span class="icon flaticon-search-1"></span> china
                </button>
                <button class="theme-button3 font120 text-white" href="#">
                  <span class="icon flaticon-search-1"></span> Student
                </button>
                <button class="theme-button3 mt-2 font120 text-white" href="#">
                  <span class="icon flaticon-search-1"></span> University
                </button>

                <button class="theme-button3 mt-2 font120 text-white" href="#">
                  <span class="icon flaticon-search-1"></span> Carrer
                </button>
              </div>

              <!-- <div class="mt-4 mb-5">
                <button class="theme-button4 font120">
                  <a class="theme-color2" href="/parttimejob"> PART TIME JOB</a>
                </button>
                <button href="/directhire" class="theme-button4 mt-2 font120">
                  <a class="theme-color2" href="/directhire">DIRECT HIRE</a>
                </button>
                <button class="theme-button4 mt-2 font120">
                  <a class="theme-color2" @click.prevent="opentierjob"
                    >TIER 2 SPONSORSHIP</a
                  >
                </button>
              </div> -->
              <!-- End Popular Search -->
            </div>
          </div>

          <div class="image-column col-lg-5 col-md-12">
            <div class="image-box">
              <div class="main-image wow fadeIn" data-wow-delay="500ms">
                <img src="images/girl.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="features__section">
      <div class="auto-container">
        <div class="row features__grid justify-content-between">
          <div class="col-lg-3 col-md-6">
            <div class="features -type-1">
              <div class="icon-wrap">
                <div><i class="bi bi-clock-history theme-color2"></i></div>
              </div>
              <div class="title">
                <a href="/" class="theme-color2">20K+ ADDMISSION COMPLETED </a>
              </div>
            </div>
          </div>

          <div class="col-lg-auto features-line-col">
            <div class="features-line"></div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="features -type-1">
              <div class="icon-wrap">
                <div><i class="fa-solid fa-person theme-color2"></i></div>
              </div>

              <div class="title">
                <a href="/" class="theme-color2">100+ UIVERSITIES</a>
              </div>
            </div>
          </div>

          <div class="col-lg-auto features-line-col">
            <div class="features-line"></div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="features -type-1">
              <div class="icon-wrap">
                <div><i class="las la-person-booth theme-color2"></i></div>
              </div>
              <div class="title">
                <a @click.prevent="" class="theme-color2">SPONSORSHIP</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Banner Section-->
    <!-- Job Categories -->
    <section class="job-categories">
      <div class="auto-container">
        <div class="sec-title text-center">
          <h2>POPULER UNIVERSITIES</h2>
          <h6 class="mt-3">
            <span class="theme-color bold-font">
              {{ jobcount.total_active_jobpost_count }}
            </span>
            ADMISSION OPEN,
            <span class="theme-color bold-font">
              {{ jobcount.todays_jobpost_count }}
            </span>
            ADDED TODDAY
          </h6>
        </div>

        <div class="row wow fadeInUp">
          <!-- Category Block -->
          <div
           
            v-for="popular in populars"
            :key="popular"
            class="category-block col-lg-4 col-md-6 col-sm-12"
          >
            <div  @click.prevent="show_university(popular.id)" class="inner-box">
              <div class="row">
                <div v-if="popular.logo" class="col-3">
                  <img
                   :src="imageurl+popular.logo"
                    alt=""
                  />
                </div>
                <div class="col-9">
                  <h4 class="mt-1">
                    <a href="#">{{ popular.name }}</a>
                  </h4>
                  <h6>
                    <span class="theme-color"> {{ popular.type }}</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Job Categories -->
    <!-- Job Categories -->
    <!--section class="job-categories style-two">
      <div class="auto-container">
        <div class="sec-title text-center">
          <h2>Popular Job Categories</h2>
          <div class="text">2020 jobs live - 293 added today.</div>
        </div>

        <div class="row wow fadeInUp"-->
    <!-- Category Block -->
    <!--div v-for="category in categories" :key="category.id" class="category-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="content">
                <span class="icon flaticon-money-1"></span>
                <h4><a href="#">{{category.cat_name}}</a></h4>
                <p>(50 open position)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section-->
    <!-- End Job Categories -->

    <!-- Commercial Ad Section -->

    <section class="testimonial-section">
      <div class="container-fluid">
        <!-- Sec Title -->
        <div class="sec-title text-center">
          <h2 class="theme-color2">WHY CHOOSE PANDAXUE ?</h2>

          <h5 class="mt-3">
            You have seen a lot of Agencies right? But do you know what we
            offer?
          </h5>
        </div>

        <div class="carousel-outer wow fadeInUp">
          <!-- Testimonial Carousel -->
          <div id="caro" class="owl-theme testimonial-carousel owl-carousel">
            <!--Testimonial Block -->
            <div class="testimonial-block">
              <div class="inner-box">
                <h4
                  style="font-size: 150% !important"
                  class="bold-font400 theme-color2"
                >
                  Embassy facing counselling
                </h4>
                <div class="text mt-3">
                  Pandaxue isn’t all about the scholarships and university
                  processing. We also experience counseling before students face
                  the embassy. We ensure the visa success rate is high by
                  training our students and prepare them for what may come.
                </div>
                <!-- <div class="info-box">
                  <div class="thumb">
                    <img src="images/com/logo2.jpg" alt="" />
                  </div>
                  <h4 class="name">Kensington, London, W10 6DG</h4>
                  <span class="designation"
                    >£11.95 per hour (London living wage)</span
                  >
                </div> -->
              </div>
            </div>

            <div class="testimonial-block">
              <div class="inner-box">
                <h4
                  style="font-size: 150% !important"
                  class="bold-font400 theme-color2"
                >
                  Trustworthy
                </h4>
                <div class="text mt-3">
                  Pandaxue has been operating for several years with high
                  success rates. People who have used our services have always
                  come back and referred their friends and relatives, which is
                  not only because of our high-end service but also because of
                  the faith that people have in us, which developed through
                  years of quality service.
                </div>
              </div>
            </div>
            <div class="testimonial-block">
              <div class="inner-box">
                <h4
                  style="font-size: 150% !important"
                  class="bold-font400 theme-color2"
                >
                  Less time required for visa processing
                </h4>
                <div class="text mt-3">
                  We at Pandaxue believe when it comes to students’ career, they
                  only deserve the best. Hence we ensure they are provided with
                  the best university they can choose from and with a subject of
                  their choosing. Our team ensures the process is as easy as
                  possible, and we guarantee the visa processing time is swift
                  so our students don’t have to go through all the hardships
                  they would have faced elsewhere.
                </div>
                <!-- <div class="info-box">
                  <div class="thumb">
                    <img src="images/com/logo2.jpg" alt="" />
                  </div>
                  <h4 class="name">Kensington, London, W10 6DG</h4>
                  <span class="designation"
                    >£11.95 per hour (London living wage)</span
                  >
                </div> -->
              </div>
            </div>
            <div class="testimonial-block">
              <div class="inner-box">
                <h4
                  style="font-size: 150% !important"
                  class="bold-font400 theme-color2"
                >
                  We provide a wide range of universities and subjects
                </h4>
                <div class="text mt-3">
                  Pandaxue works with thousands of universities worldwide; we
                  scout the best ones out there for our students. When
                  affiliating with universities, the best interests of our
                  students are always at heart. And the quality of our service
                  also makes the universities eager to work with us. As a
                  result, we can provide a wide range of universities and
                  subjects that a student might wish to pursue.
                </div>
                <!-- <div class="info-box">
                  <div class="thumb">
                    <img src="images/com/logo2.jpg" alt="" />
                  </div>
                  <h4 class="name">Kensington, London, W10 6DG</h4>
                  <span class="designation"
                    >£11.95 per hour (London living wage)</span
                  >
                </div> -->
              </div>
            </div>
            <div class="testimonial-block">
              <div class="inner-box">
                <h4
                  style="font-size: 150% !important"
                  class="bold-font400 theme-color2"
                >
                  Scholarship guaranteed
                </h4>
                <div class="text mt-3">
                  Pandaxue works with thousands of universities worldwide. Due
                  to our good reputation, we have bagged several prominent
                  scholarships from numerous universities, so when applying
                  through us, we ensure you get the best scholarship based on
                  your merit.
                </div>
                <!-- <div class="info-box">
                  <div class="thumb">
                    <img src="images/com/logo2.jpg" alt="" />
                  </div>
                  <h4 class="name">Kensington, London, W10 6DG</h4>
                  <span class="designation"
                    >£11.95 per hour (London living wage)</span
                  >
                </div> -->
              </div>
            </div>

            <div class="testimonial-block">
              <div class="inner-box">
                <h4
                  style="font-size: 150% !important"
                  class="bold-font400 theme-color2"
                >
                  Early processing
                </h4>
                <div class="text mt-3">
                  We at Pandaxue ensure your application is dealt with with
                  utmost care and sincerity. We provide you are satisfied with
                  our service and maintain early processing times to apply
                  through us as less strenuous as possible.
                </div>
                <!-- <div class="info-box">
                  <div class="thumb">
                    <img src="images/com/logo2.jpg" alt="" />
                  </div>
                  <h4 class="name">Kensington, London, W10 6DG</h4>
                  <span class="designation"
                    >£11.95 per hour (London living wage)</span
                  >
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="job-section-five style-two">
      <div class="auto-container">
        <div class="sec-title text-center">
          <h2 class="theme-color2">TOP OFFERED UNIVERSITY</h2>

          <h5 class="mt-2">Check the Best offer</h5>
        </div>

        <div style="z-index: 9" class="row wow fadeInUp px-5">
          <div v-for="topfive in topfives" :key="topfive" class="col-md-6 p-3">
            <div class="custom-card p-3">
              <div class="row">
                <div class="col-md-2 text-center m-auto">
                  <img
                    style="height: 50px; width: 50px"
                    src="images/university/scu.jpg"
                  />
                </div>

                <div class="col-md-7 mt-sm-2">
                  <h6
                    class="font110"
                    style="text-align: left; font-weight: 500"
                  >
                   {{ topfive.name }}
                  </h6>

                  <div class="row mt-1">
                    <div class="col-8">
                      <span class="icon flaticon-briefcase"></span>

                      <span class="small-font ms-2"> Full scholorship</span>
                    </div>

                    <div class="col-4">
                      <span class="icon flaticon-map-locator"></span>
                      <span class="small-font ms-2">China</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <span class="icon flaticon-clock-3"></span>

                      <span class="small-font ms-2"> 11 hours ago </span>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 text-lg-center mt-sm-3 m-lg-auto">
                  <button href="#" class="theme-button mt-2 p-2">
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>

        

        

       
        </div>
      </div>
    </section>
    <!-- End Testimonial Section -->

    <!-- steps Section -->
    <section class="steps-section pt-0">
      <div class="auto-container">
        <div class="row">
          <div class="image-column mt-4 col-lg-7 col-md-12 col-sm-12">
            <div class="inner-column wow fadeInUp p-5">
              <figure class="">
                <img
                  height="100%"
                  width="110%"
                  src="images/resource/ledy.jpg"
                  alt=""
                />
              </figure>
              <!-- Count Employers -->
              <!--div class="count-employers wow fadeInUp">
                <span class="title">300k+ Employers</span>
                <figure class="image"><img src="images/resource/multi-peoples.png" alt=""></figure>
              </div-->
            </div>
          </div>

          <div class="content-column col-lg-5 col-md-12 col-sm-12">
            <div class="inner-column wow fadeInUp">
              <div class="sec-title">
                <h2>STEPS OF APPLICATION</h2>
                <h5 class="text font110"></h5>
                <ul class="steps-list">
                  <li>
                    <span class="count">1</span> Need to prepare application
                    documents for submission
                  </li>
                  <li>
                    <span class="count">2</span>We will proceed for next step
                    with your application materials for review
                  </li>
                  <li>
                    <span class="count">3</span> You will get admission result
                    with a very short period of time after review
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Steps Section -->

    <!-- Job Section -->

    <section class="job-section-five style-two iam">
      <div class="auto-container">
        <div class="row wow fadeInUp">
          <div class="recent-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
            <div class="content-column">
              <div class="inner-column iamrec wow fadeInUp">
                <div class="sec-title">
                  <h1>ARE YOU A STUDENT ?</h1>
                  <h5 class="text font110 text-justify">
                    You will get a high services from us!
                  </h5>
                </div>

                <a
                  style="font-size: 110%; z-index: 30; margin-top: 20px"
                  href="/"
                  class="btn-style-one theme-color-bg"
                  >Apply Now</a
                >
              </div>
            </div>
          </div>

          <div class="featured-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
            <div class="image-column">
              <figure>
                <img src="images/student.jpg" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Job Section -->
    <!-- End Job Section -->

    <!-- Recruiter Section -->
    <!-- <section class="recruiter-section">
      <div class="outer-box">
        <div class="image-column">
          <figure><img src="images/resource/Recruiter.jpg" alt="" /></figure>
        </div>
        <div class="content-column">
          <div class="inner-column wow fadeInUp">
            <div class="sec-title">
              <h2>I am Recruiter</h2>
              <div class="text">
                One of our One of our jobs has some kind of flexibility jobs has
                some kind of flexibility option such as telecommuting, a
                part-time schedule or a flexible or flextime.
              </div>
              <a
                style="font-size: 130%; z-index: 30"
                href="/login"
                class="theme-btn btn-style-one"
                >Post New Job</a
              >
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End Recruiter Section -->
    <!-- Top Companies -->
    <br />
    <section class="top-company-section playout-pt-60 layout-pb-60">
      <div class="auto-container">
        <div class="row justify-content-between align-items-end">
          <div class="col-lg-12">
            <div class="sec-title mb-0">
              <br />
              <br /><br />
              <h2 class="color-blue-dark fw-700 text-center">Our activity</h2>
              <div class="text text-center font110">
                Some of the companies we've helped recruit excellent applicants
                over the years.
              </div>
            </div>
          </div>

          <!-- <div class="col-auto">
            <a href="#" class="button -arrow text-dark-blue">
              Browse
              <span class="fa fa-angle-right"></span>
            </a>
          </div> -->
        </div>

        <div class="carousel-outer pt-50 wow fadeInUp">
          <div class="companies-carousel owl-carousel owl-theme default-dots">
            <!-- Company Block -->

            <div class="company-block">
              <div class="inner-box">
                <figure class="image">
                  <img src="images/com/logo2.jpg" alt="" />
                </figure>
                <h4 class="name">SICHUAN</h4>
                <div class="location">
                  <i class="flaticon-map-locator"></i> China
                </div>
                <!-- <a href="#" class="theme-button2 p-3">22 Open Position</a> -->
              </div>
            </div>

            <div class="company-block">
              <div class="inner-box">
                <figure class="image">
                  <img src="images/com/logo3.jpg" alt="" />
                </figure>
                <h4 class="name">YANGZHOU</h4>
                <div class="location">
                  <i class="flaticon-map-locator"></i> Cina
                </div>
                <!-- <a href="#" class="theme-button2 p-3">22 Open Position</a> -->
              </div>
            </div>
            <div class="company-block">
              <div class="inner-box">
                <figure class="image">
                  <img src="images/com/logo4.jpg" alt="" />
                </figure>
                <h4 class="name">SICHUAN</h4>
                <div class="location">
                  <i class="flaticon-map-locator"></i> Cina
                </div>
                <!-- <a href="#" class="theme-button2 p-3">22 Open Position</a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End Page Title-->

    <!-- Testimonials Section -->
    <section
      id="testimonials-section"
      class="layout-pt-60 layout-pb-120"
      style="background-color: #53cecb"
    >
      <div class="auto-container">
        <div class="row justify-content-center text-center">
          <div class="col-auto">
            <div class="">
              <h2 class="fw-700">People's opinions</h2>
              <div class="font120 mt-9">
                people are saying about our service and quality
              </div>
            </div>
          </div>
        </div>

        <div
          class="job-carousel pt-50 wow fadeInUp owl-carousel owl-theme default-dots"
        >
          <!-- Job Block -->
          <div>
            <div class="testimonial -type-2 text-center">
              <div class="image">
                <img class="mx-auto" src="images/ope/ope2.jpg" alt="image" />
              </div>

              <div class="content">
                <h4 class="font130">Best student site</h4>
                <p class="font110">
                  As student visiting the this site, I was thoroughly impressed
                  by the strong emphasis placed on creating a positive
                </p>
              </div>

              <div class="author">
                <div class="name">Abu Saeed Sabuj</div>
                <div class="job">Student</div>
              </div>
            </div>
          </div>

          <div>
            <div class="testimonial -type-2 text-center">
              <div class="image">
                <img class="mx-auto" src="images/ope/ope3.jpg" alt="image" />
              </div>

              <div class="content">
                <h4 class="font130">Quality Service</h4>
                <p class="font110">
                  As an HR manager visiting this student, I was thoroughly
                  impressed by the strong emphasis placed on creating a positive
                </p>
              </div>

              <div class="author">
                <div class="name">Devon Lane</div>
                <div class="job">Student</div>
              </div>
            </div>
          </div>

          <div>
            <div class="testimonial -type-2 text-center">
              <div class="image">
                <img class="mx-auto" src="images/ope/ope4.jpg" alt="image" />
              </div>

              <div class="content">
                <h4 class="font130">Best user exprience</h4>
                <p class="font110">
                  As an HR manager visiting the this site, I was thoroughly
                  impressed by the strong emphasis placed on creating a positive
                </p>
              </div>

              <div class="author">
                <div class="name">Andreea</div>
                <div class="job">Student</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Testimonials Section -->

    <!-- Call To Action Three -->
    <section class="let-section call-to-action-three theme-color-bg4">
      <div class="auto-container">
        <div class="outer-box">
          <div class="sec-title">
            <h2>Let student find you</h2>
            <div class="text">
              Since pandaxue is a premium job board that continuously earns the
              top spot in our rankings for both applyer and student, we advise
              you to give it a try.
            </div>
          </div>

          <div class="btn-box">
            <a href="/job" class="theme-btn btn-style-one theme-color-bg"
              ><span class="btn-title">Search University</span></a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- End Call To Action -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import Constant from "@/interceptor/Constant.js";

export default {
  name: "HomeView",
  data() {
    return {
      show: false,
      load: false,
      imageurl : Constant.filebaseurl+"/image/university/",


      categories: [],
      JobsCategory: [
        {
          categorytitle: "Accounting / Finance",
          position: "(50 open positions",
        },
        { categorytitle: "Marketing", position: "(160 open positions" },
        { categorytitle: "Design", position: "(30 open positions)" },
        { categorytitle: "Development", position: "(25 open positions" },
        { categorytitle: "Project Management", position: "(20 open positions" },
        { categorytitle: "Health and Care", position: "(40 open positions" },
      ],

      caticon: [
        { iconurl: "fa-solid fa-hotel" },
        { iconurl: "fa-solid fa-utensils" },
        { iconurl: "fa-solid fa-wine-bottle" },
        { iconurl: "fa-solid fa-kitchen-set" },
        { iconurl: "fa-solid fa-calendar-check" },
        { iconurl: "fa-solid fa-broom" },
        { iconurl: "fa-solid fa-handshake" },
        { iconurl: "fa-solid fa-burger" },
        { iconurl: "fa-solid fa-user-nurse" },
      ],

      images: [],
      jobcount: "",

      jobs: [],
      hotjobs: [],
      topjob_data: null,
      ano_categories: [],
      search_key: "",
      popupads: [],
      adboards: [],
      universities: [],
      topfives:[],
      populars: [],
    };
  },

  methods: {
    getjob() {
      axios
        .get("anonymous/job-list/")
        .then((res) => {
          this.jobs = res.data.results;

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    gethotjob() {
      axios
        .get("anonymous/featured-job/")
        .then((res) => {
          this.hotjobs = res.data.results;

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    get_total_job() {
      axios
        .get("anonymous/job-count/")
        .then((res) => {
          this.jobscount = res.data.results;

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    get_category() {
      axios
        .get("anonymous/job-category/", { ignore: "1" })
        .then((res) => {
          this.ano_categories = res.data;

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    job_count() {
      axios
        .get("anonymous/job-count/", { ignore: "1" })
        .then((res) => {
          this.jobcount = res.data;

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    popup() {
      $("#homepopup").addClass("popshow");
      $("#popbg").addClass("lowopacity");
      $("body").addClass("bodylock");
      $("html").addClass("bodylock");
    },

    pophide() {
      $("#homepopup").addClass("pophide");
      $("#popbg").addClass("highopacity");
      $("body").addClass("bodyunlock");
      $("html").addClass("bodyunlock");
    },

    search(key) {
      if (this.search_key != "") {
        this.$router.push({ name: "search", query: { key: this.search_key } });
      }

      if (key != "") {
        this.$router.push({ name: "search", query: { key: key } });
      }
    },

    fetchImages() {
      const jsonData = [
        { id: 1, url: "images/resource/pop1.jpg", title: "Title1" },
        { id: 2, url: "images/resource/pop2.jpg", title: "title2" },
        { id: 3, url: "images/resource/pop3.jpg", title: "title3" },
      ];

      this.images = jsonData;
    },

    topud() {
      axios
        .get("/anonymous/top-recruiter/", { ignore: "1" })
        .then((res) => {
          if (res.status == 200) {
            this.load = true;
            this.topjob_data = res.data;
            console.log("Top company received");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    openjob(uuid) {
      if (uuid != null) {
        this.$router.push({ name: "job-single", query: { uuid: uuid } });
      }
    },
    opencatjob(catid, cat_name) {
      if (catid != null && cat_name != null) {
        this.$router.push({
          name: "job-category",
          query: { cart_id: catid, cart_name: cat_name },
        });
      }
    },
    get_university() {
      axios
        .get("/api/admin/university")
        .then((res) => {
          if (res.status == 200) {
            this.universities = res.data.data;

            console.log(this.universities);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    opentierjob() {
      this.$router.push({
        name: "tiertojob",
        query: { cart_id: 40, cart_name: "Tier 2 Job" },
      });
    },

    // getpoup() {
    //   axios
    //     .get("anonymous/popup_ad_board/", { ignore: "1" })
    //     .then((res) => {
    //       this.popupads = res.data;

    //       if (res.status == 200) {
    //         console.log("ok");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //     });
    // },
    async getadboard() {
      const response = await axios.get("anonymous/ad_board/", { ignore: "1" });

      if (response.status == 200) {
        this.adboards = await response.data;
        // var element = document.getElementById("caro");
        // element.classList.add("testimonial-carousel");
        // element.classList.add("owl-carousel");

        console.log(response.data);
      }
    },

    show_university(id){

      if(id){

        this.$router.push({ name: "university", query: { id: id } });


      }


    },
    topu(){
      axios
        .get("api/web/university/topfive")
        .then((res) => {
         
            this.topfives = res.data.data;

          
        })
        .catch((error) => {
          console.log(error.response);
        });

    },

    
    popular(){
      axios
        .get("api/web/university/popular")
        .then((res) => {
         
            this.populars = res.data.data;

          
        })
        .catch((error) => {
          console.log(error.response);
        });

    }
  },

  
  

  mounted: function () {
  
    this.topu();
    this.popular()
  },
};
</script>

<style>
#mySidenav {
  position: fixed;

  top: 250px;

  right: 0;
}

/* Style the links inside the sidenav */
#mySidenav a {
  position: absolute; /* Position them relative to the browser window */
  left: -20px; /* Position them outside of the screen */
  transition: 0.3s; /* Add transition on hover */
  padding: 15px 15px 15px 30px; /* 15px padding */
  width: 150px; /* Set a specific width */
  text-decoration: none; /* Remove underline */
  text-orientation: sideways-right;
  font-size: 20px; /* Increase font size */
  color: white; /* White text color */
  border-radius: 0 5px 5px 0; /* Rounded corners on the top right and bottom right side */
  overflow: hidden; /* Hide scrollbars */
  overflow-y: hidden; /* Hide vertical scrollbar */
  /*overflow-x: hidden; /* Hide horizontal scrollbar */
  z-index: 14;
  opacity: 100% !important;
}

#mySidenav a:hover {
  left: 0; /* On mouse-over, make the elements appear as they should */
}

/* The about link: 20px from the top with a green background */
#about {
  top: 20px;

  background-color: #04aa6d;
}

#blog {
  top: 80px;
  background-color: #2196f3; /* Blue */
}

#projects {
  top: 140px;
  background-color: #f44336; /* Red */
}

#contact {
  top: 200px;
  background-color: #555; /* Light Black */
}
</style>
