<template>
    
    <div>
    
  
  <!-- Dashboard -->
  <section class="user-dashboard">
    <div class="dashboard-outer">
      <div class="upper-title-box">
        <h3>Student Dashboard</h3>
        <div class="text">Ready to jump back in?</div>
      </div>
      <div class="row">
        <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <div class="ui-item">
            <div class="left">
              <i class="icon flaticon-briefcase"></i>
            </div>
            <div class="right">
              <h4>{{ dashboardcount.total_approved_job_posts }}</h4>
              <p>University</p>
            </div>
          </div>
        </div>
        <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <div class="ui-item ui-red">
            <div class="left">
              <i class="icon las la-exclamation"></i>
            </div>
            <div class="right">
              <h4>{{ dashboardcount.reviewing_job_posts }}</h4>
              <p>Application</p>
            </div>
          </div>
        </div>
        <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <div class="ui-item ui-yellow">
            <div class="left">
              <i class=" icon las la-low-vision"></i>
            </div>
            <div class="right">
              <h4>{{ dashboardcount.non_viewed_applications}}</h4>
              <p>Program</p>
            </div>
          </div>
        </div>
        <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <div class="ui-item ui-green">
            <div class="left">
              <i class="icon la la-file-invoice"></i>
            </div>
            <div class="right">
              <h4>{{ dashboardcount.total_applications }}</h4>
              <p>Department</p>
            </div>
          </div>
        </div>
  
        <!-- <div class="ui-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
          <div class="ui-item ui-green">
            <div class="left">
              <i class="icon la la-file-invoice"></i>
            </div>
            <div class="right">
              <h4>{{ dashboardcount.non_viewed_applications }}</h4>
              <p>Non Viewed</p>
            </div>
          </div>
        </div> -->
      </div>
  
      <div class="row">
  
  
        <div class="col-xl-7 col-lg-12">
          <!-- Graph widget -->
          <div class="graph-widget ls-widget">
            <div class="tabs-box">
              <div class="widget-title">
                <h4>University views</h4>
                <div class="chosen-outer">
                  <!--Tabs Box-->
                  <select class="chosen-select">
                    <option>Last 6 Months</option>
                    <option>Last 12 Months</option>
                    <option>Last 16 Months</option>
                    <option>Last 24 Months</option>
                    <option>Last 5 year</option>
                  </select>
                </div>
              </div>
  
              <div class="widget-content">
                <canvas id="chart" width="100" height="45"></canvas>
              </div>
            </div>
          </div>
        </div>
  
        <div class="col-xl-5 col-lg-12">
          <!-- Notification Widget -->
          <div class="notification-widget ls-widget">
            <div class="widget-title">
              <h4>Notifications</h4>
            </div>
            <div class="widget-content">
              <ul class="notification-list">
                <li><span class="icon flaticon-briefcase"></span> <strong>Wade Warren</strong> applied  <span class="colored">WU</span></li>
                <li><span class="icon flaticon-briefcase"></span> <strong>Henry Wilson</strong> applied <span class="colored">EU</span></li>
                <li class="success"><span class="icon flaticon-briefcase"></span> <strong>Raul Costa</strong> applied  <span class="colored">HG</span></li>
                <li><span class="icon flaticon-briefcase"></span> <strong>Jack Milk</strong> applied  <span class="colored">JU</span></li>
                <li class="success"><span class="icon flaticon-briefcase"></span> <strong>Michel Arian</strong> applied  <span class="colored">DU</span></li>
                <li><span class="icon flaticon-briefcase"></span> <strong>Ali Tufan</strong> applied  <span class="colored">BU</span></li>
              </ul>
            </div>
          </div>
        </div>
  
  
  
      </div>
    </div>
  </section>
  <!-- End Dashboard -->
  
  <!-- Copyright -->
  <!--div class="copyright-text">
    <p>© 2021 Superio. All Right Reserved.</p>
  </div-->
  </div>
  </template>
  
  <script>
  import axios from "axios";
  import vcookie from 'vue-cookie'
  
  export default {
  
      name:'DashboardAdminView',
  
      data(){
  
        return{
  
          dashboardcount: "",
        }
  
  
      },
      
  
      methods:{
  
        logout(){
   
   const user = JSON.parse(vcookie.get('user'));
   
   if(user){
      
    vcookie.delete('user')
   
    this.$router.push({name:'home'})
    location.reload()
   
   }
   },
  
   dashboard_count() {
        axios
          .get("recruiter/dashboard/")
          .then((res) => {
            this.dashboardcount = res.data;
  
            if (res.status == 200) {
              console.log("ok");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
  
  
  },
  mounted: function () {
      this.dashboard_count();
      
    },
  
  
  
  }
  </script>
  
  <style>
  
  </style>