<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/employee-dashboard">
              <i class="la la-home"></i> Dashboard</a
            >
          </li>
          <li class="active">
            <a href="/employer-profile"
              ><i class="la la-user-tie"></i>Company Profile</a
            >
          </li>
          <li>
            <a href="/employer-postjob"
              ><i class="la la-paper-plane"></i>Post a New Job</a
            >
          </li>
          <li>
            <a href="/employer-managejob"
              ><i class="la la-briefcase"></i> Manage Jobs
            </a>
          </li>
          <!-- <li>
            <a href="/employer-allapplicants"
              ><i class="la la-file-invoice"></i> All Applicants</a
            >
          </li> -->
          <li>
            <a href="#"
              ><i class="la la-bookmark-o"></i>Contractual Applicants</a
            >
          </li>
          <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
          <li>
            <a href="#"
              ><i class="la la-trash"></i>Delete Profile</a
            >
          </li>
          <li>
            <a href="#"
              ><i class="la la-lock"></i>Change Password</a
            >
          </li>
          <li>
            <a  @click.prevent="logout"><i class="la la-sign-out"></i>Logout</a>
          </li>
          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Company Profile!</h3>
          <div class="text">Ready to jump back in?</div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <h4>My Profile</h4>
                </div>

                <div class="widget-content">
                  <form @submit.prevent="addCompany" class="default-form">
                    <div class="row">
                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Company Code</label>
                        <input
                          type="text"
                          v-model="code"
                          placeholder="Company Code"
                        />
                      </div>

                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Street</label>
                        <input
                          type="text"
                          v-model="street"
                          placeholder="Street"
                        />
                      </div>

                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>House</label>
                        <input
                          type="text"
                          v-model="house"
                          placeholder="House"
                        />
                      </div>

                      <!-- Input -->

                      <!-- Input -->

                      <div class="form-group col-lg-6 col-md-12">
                        <label>City</label>
                        <input
                          v-model="city"
                          type="text"
                          name="name"
                          placeholder="City"
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Province</label>
                        <input
                          v-model="province"
                          type="text"
                          name="name"
                          placeholder="Province"
                        />
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>County</label>
                        <select v-model="county">
                          <option value="Kent">Kent</option>
                          <option value="Surey">Surrey</option>
                        </select>
                      </div>

                      <div class="form-group col-lg-12 col-md-12">
                        <label>Description</label>
                        <textarea
                          v-model="description"
                          placeholder="Description"
                        ></textarea>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Contact Person Name</label>
                        <input
                          v-model="con_p_name"
                          type="text"
                          name="name"
                          placeholder="Contact Person Name"
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-6">
                        <label>Contact Person Designation</label>
                        <input
                          v-model="cont_per_designation"
                          type="text"
                          placeholder="Contact Person Designation"
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Contact Person Email</label>
                        <input
                          v-model="con_p_mail"
                          type="text"
                          name="name"
                          placeholder="Contact Person Email"
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Contact Person Mobile</label>
                        <input
                          v-model="con_p_mob"
                          type="text"
                          name="name"
                          placeholder="Contact Person Mobile"
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Organization name</label>
                        <input
                          v-model="org_name"
                          type="text"
                          name="name"
                          placeholder="Organization name"
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Establist Year</label>
                        <input
                          v-model="est_year"
                          type="text"
                          name="name"
                          placeholder="Establist Year"
                        />
                      </div>

                      <!-- Input -->
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Organization size</label>
                        <select v-model="org_size">
                          <option value="small">small</option>
                          <option value="medium">medium</option>
                          <option value="large">large</option>
                        </select>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Business ID</label>
                        <input
                          v-model="business_id"
                          type="text"
                          name="name"
                          placeholder="Business ID"
                        />
                      </div>

                      <!-- Search Select -->
                      <!-- <div class="form-group col-lg-6 col-md-12">
                        <label>Multiple Select boxes </label>
                        <select data-placeholder="Categories" class="chosen-select multiple" multiple tabindex="4">
                          <option value="Banking">Banking</option>
                          <option value="Digital&Creative">Digital & Creative</option>
                          <option value="Retail">Retail</option>
                          <option value="Human Resources">Human Resources</option>
                          <option value="Management">Management</option>
                        </select>
                      </div> -->
                      <div class="form-group col-lg-12 col-md-12">
                        <label>Business Description</label>
                        <textarea
                          v-model="business_description"
                          placeholder="Business Description"
                        ></textarea>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Trade License</label>
                        <input
                          v-model="trade_license"
                          type="text"
                          name="name"
                          placeholder="Trade License"
                        />
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Organization type</label>
                        <select v-model="org_type_id">
                          <option>Select organization type</option>
                          <option
                            v-for="orgtype in org_types"
                            :key="orgtype.id"
                            :value="orgtype.id"
                          >
                            {{ orgtype.org_type }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Website</label>
                        <input
                          v-model="website"
                          type="text"
                          name="name"
                          placeholder="Website"
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Country</label>
                        <select v-model="country">
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="UK">UK</option>
                        </select>
                      </div>

                      <div class="uploading-outer">
                        <div class="uploadButton">
                          <input
                            class="uploadButton-input"
                            type="file"
                            @change="handleFileUpload($event)"
                            accept="image/*"
                            id="upload"
                            multiple
                          />
                          <label
                            class="uploadButton-button ripple-effect"
                            for="upload"
                            >Browse Logo</label
                          >
                          <span class="uploadButton-file-name"></span>
                        </div>
                        <!-- <button @click.prevent="add_logo"  type="submit" class="btn btn-primary" >Upload</button> -->
                      </div>

                      <!-- About Company -->

                      <!-- Input -->
                      <div class="form-group col-lg-12 col-md-12">
                        <button type="submit" class="theme-btn btn-style-one">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->
    <!-- End Dashboard -->

    <!-- Copyright -->
    <!--div class="copyright-text">
  <p>© 2021 Superio. All Right Reserved.</p>
</div-->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EmployerProfileView",

  data() {
    return {
      org_types: [],
      logo: [],
      file: "",
      county: "",
      country: "",
      org_size: "",
    };
  },


methods:{


add_org_type()


{ axios.get("recruiter/org_type/",
          ).then( res => {
            if(res.status==200){
              this.org_types=res.data

              



            }

     }
   )
.catch(error => {
 console.log(error.response)
});
},

    handleFileUpload(event) {
      this.file = event.target.files[0];
    },

    addCompany() {
      axios
        .post("recruiter/create-profile/", {
          code: this.code,
          is_accepted: true,
          street: this.street,
          house: this.house,
          county: this.county,
          city: this.city,
          province: this.province,
          country: this.country,
          description: this.description,
          con_per_name: this.con_p_name,
          cont_per_designation: this.cont_per_designation,
          cont_per_mail: this.con_p_mail,
          cont_per_mobile: this.con_p_mob,
          org_name: this.org_name,
          est_year: this.est_year,
          org_size: this.org_size,
          business_id: this.business_id,
          business_description: this.business_description,
          trade_license: this.trade_license,
          org_type: this.org_type_id,
          website: this.website,
        })
        .then((res) => {
          if (res.status == 201) {
            console.log(res.data);
            alert("Profile Created");
            this.add_logo();
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    add_logo() {
      if (this.file != "") {
        let formData = new FormData();
        formData.append("logo", this.file);

        axios
          .post("recruiter/logo/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              console.log(res.data);
              alert("Logo added");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      } else {
        // alert("Select a logo first")
      }
    },

    re_logo() {
      axios
        .get("recruiter/logo/")
        .then((res) => {
          this.logo = res.data;

          if (res.status == 200) {
            console.log("ok");
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted: function () {
    this.add_org_type(), this.re_logo();
  },
};
</script>

<style>
</style>