<template>
  <br>
  <br>
  <br>
    <!-- TNC Section -->
    <section class="tnc-section">
      <div class="auto-container">
        <div class="sec-title text-center">
          <h2>Privacy and security</h2>
        </div>

        

        <div class="text-box">
          <h3>Overview:</h3>
          <p>The hive4work.co.uk websites are maintained by Hive4work.</p>
          <p>This Privacy Statement applies when you:</p>
          <ol>
           <li>1. visit the publicly accessible pages on the websites, including <a href="https://www.hive4work.co.uk/">https://www.hive4work.co.uk/</a></li>
           <li>2. create a profile to search or apply for jobs on the <a href="https://www.hive4work.co.uk/">hive4work.co.uk/</a> websites; and/or</li>
           <li>3. apply for a job advertised on the <a href="https://www.hive4work.co.uk/">hive4work.co.uk/</a> websites.</li>
          </ol> 
          <p>You should be aware that certain information relating to your use of these websites will be collected each time you visit a <a href="https://www.hive4work.co.uk/">hive4work.co.uk/</a> website. Additional information will be collected if you create a profile to enable you to search for available positions or make an application for a position that is advertised on the website, which is done through a related internet portal. </p>
          <p>You can find more information in our Privacy Management Plan, which explains how we manage personal information in line with our obligations.</p>
        </div>


        <div class="text-box">
          <h3>What information is collected through the Hive4work website?</h3>
          <p>Each time you look at the pages on the <a href="https://www.hive4work.co.uk/">www.hive4work.co.uk/</a> website, information is automatically recorded relating to the pages visited by you, the duration of your view of this page, the location you visited immediately prior to visiting the website and your navigation habits.</p>
          <p>If you choose to use the jobs portal to search or apply for employment with Hive4work, you will be asked to create a personal profile and provide additional information as part of the job application process. This means that you will be required to provide the information indicated in the electronic forms that are filled out as part of the process of setting up your profile and applying for a job. This includes (but is not limited to) information such as your name, address and contact details, your employment history and referees.</p>
          <p>If you would like to access information held about you or you have any other questions about this Privacy Statement, please contact the hive4work .</p>
        </div>
      



      </div>
    </section>
    <!-- End TNC Section -->


 
    
    
</template>
  
 <script>
  export default {
    name:'PrivacyView'
      
  
  }
  </script>
  
  <style>
  
  
  </style>
  
  
  