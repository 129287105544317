<template>
  <div>
    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">
      <div class="sidebar-inner">
        <ul class="navigation">
          <li>
            <a href="/employee-dashboard">
              <i class="la la-home"></i> Dashboard</a
            >
          </li>
          <li>
            <a href="/employer-managejob"
              ><i class="la la-user-tie"></i>All University</a
            >
          </li>
          <li >
            <a href="/employer-postjob"
              ><i class="la la-paper-plane"></i>Add University</a
            >
          </li>
          <li class="active">
            <a href="/employer-managejob"
              ><i class="la la-briefcase"></i> Category
            </a>
          </li>
          <li>
            <a href="/employer-managejob"
              ><i class="la la-briefcase"></i> Department
            </a>
          </li>
          <li>
            <a href="/employer-managejob"
              ><i class="la la-briefcase"></i> Program
            </a>
          </li>
          <!-- <li>
            <a href="/employer-allapplicants"
              ><i class="la la-file-invoice"></i> All Applicants</a
            >
          </li> -->
          <li>
            <a href="#"
              ><i class="la la-bookmark-o"></i>Contractual Applicants</a
            >
          </li>
          <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
          <li>
            <a href="#"><i class="la la-trash"></i>Delete Profile</a>
          </li>
          <li>
            <a href="#"><i class="la la-lock"></i>Change Password</a>
          </li>

          <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
          <li>
            <a @click.prevent="logout"><i class="la la-sign-out"></i>Logout</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- End User Sidebar -->

    <!-- Dashboard -->
    <section @submit.prevent="postjob" class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Add Category</h3>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box p-3">
                <div class="widget-content">
                  <form class="default-form">
                    <div class="row">
                      <!-- Input -->

                      <div class="form-group col-lg-12 col-md-12">
                        <label>Category Name*</label>
                        <input
                          type="text"
                          v-model="name"
                          placeholder="Category Name"
                          required
                        />
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Category code</label>
                        <input
                          type="text"
                          v-model="username"
                          placeholder="Category code"
                        />
                      </div>

                    
                    
                     

                      <!-- About Company -->
                    
                  

                 
                
                      

                 

          
              

                 
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Status</label>
                        <select  required>
                          <option disabled value="null">Select a option</option>

                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </select>
                      </div>

                      <div class="form-group col-lg-6 col-md-12">
                        <label>Logo</label>
                        <input class="form-control"
                          type="file"
                        
                          placeholder="Total program"
                          required
                        />
                      </div>
                  

                      <!-- Input -->
                      <div class="form-group col-lg-12 col-md-12 text-right">
                        <button type="submit" class="theme-btn btn-style-one">
                          Add category
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Dashboard -->
    <!-- End Dashboard -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "EmployerPostJobView",

  data() {
    return {
      skill: [],
      age: [],
      experience: [],
      keywords: [],
      add: "",
      add1: "",
      add2: "",
      add3: "",
      add4: "",
      add5: "",
      add6: "",

      categories: [],
      salary: [],
      benefit: [],
      others_benefit: [],
      featured: false,
    };
  },

  methods: {
    addskill() {
      if (this.skill.length < 10) {
        this.skill.push(this.adds);
        this.add = "" + this.skill;
      } else {
        alert("Skills Max length will be 10");
      }
    },
    addsage() {
      if (this.age.length < 10) {
        this.age.push(this.age_range);
        this.add1 = "" + this.age;
      } else {
        alert("Age Range Max length will be 2");
      }
    },

    addexperiance() {
      if (this.experience.length < 2) {
        this.experience.push(this.experience_range);
        this.add2 = "" + this.experience;
      } else {
        alert("Experience Max length will be 2");
      }
    },
    salaryrange() {
      if (this.salary.length < 2) {
        this.salary.push(this.salary_range);
        this.add4 = "" + this.salary;
      } else {
        alert("Salary range Max length will be 2");
      }
    },

    addbenefit() {
      if (this.benefit.length < 10) {
        this.benefit.push(this.benefits);
        this.add5 = "" + this.benefit;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    add_others_benefit() {
      if (this.others_benefit.length < 10) {
        this.others_benefit.push(this.others_benefits);
        this.add6 = "" + this.others_benefit;
      } else {
        alert("Benefits range Max length will be 10");
      }
    },

    addkeywords() {
      this.keywords.push(this.keyword);
      this.add3 = "" + this.keywords;
    },

    category() {
      axios
        .get("recruiter/job_category/")
        .then((res) => {
          this.categories = res.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    postjob() {
      axios
        .post("api/university/create", {
          name: this.name,
          username: this.username,
          code: this.code,
          short_name: this.short_name,
          email: this.email,
          total_student: this.total_student,
          requirement: this.requirement,
          contact_no: this.contact_no,
          introduction: this.introduction,
          description: this.description,
          accommodation: this.accommodation,
          school: this.school,
          program_id: this.program_id,
          international_student: this.international_student,
          founded_at: this.founded_at,
          admissions_process: this.admissions_process,
          reviews: this.reviews,
          city: this.city,
          total_section: this.total_section,
          total_program: this.total_program,
          type: this.type,
          status: this.status,
        
        })
        .then((res) => {
          if (res.status == 200) {

            alert("Uinversity created successfully")
     
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  mounted: function () {
    this.category();
  },
};
</script>



<style>
.button_plus {
  position: absolute;
  width: 35px;
  height: 35px;
  background: #fff;
  cursor: pointer;
  border: 2px solid #095776;
  display: flex;

  /* Mittig */
  top: 44%;
  left: 90%;
}

.button_plus:after {
  content: "";
  position: absolute;
  transform: translate(-50%, -50%);
  height: 4px;
  width: 50%;
  background: #095776;
  top: 50%;
  left: 50%;
}

.button_plus:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #095776;
  height: 50%;
  width: 4px;
}

.button_plus:hover:before,
.button_plus:hover:after {
  background: #fff;
  transition: 0.2s;
}

.button_plus:hover {
  background-color: #095776;
  transition: 0.2s;
}
</style>