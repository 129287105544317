<template>

    <div class="page-wrapper auth">
    

    <auth-header-view></auth-header-view>
    <router-view></router-view>
    <auth-footer-view></auth-footer-view>

    
    
    </div>
        
      
    </template>
    
    <script>
import AuthHeaderView from '@/components/auth/AuthHeaderView.vue'
import AuthFooterView from '@/components/auth/AuthFooterView.vue'
 
    export default {
        name:'AuthMasterView',
        components:{AuthHeaderView,AuthFooterView},
    }
    </script>
    
    <style>
    
    </style>