<template>
    <div>


    <!-- Sidebar Backdrop -->
    <div class="sidebar-backdrop"></div>

    <!-- User Sidebar -->
    <div class="user-sidebar">

      <div class="sidebar-inner">
        <ul class="navigation">
          <li><a href="/candidate-dashboard"> <i class="la la-home"></i> Dashboard</a></li>
                <li><a href="/candidate-profile"><i class="la la-user-tie"></i>My Resume</a></li>
                <!--li><a href="/candidate-resume"><i class="la la-file-invoice"></i>My Resume</a></li-->
                <!--li><a href="/candidate-CvManager"><i class="la la-file-invoice"></i>Cv Manager</a></li-->
                <li class="active"><a href="/candidate-findjob"><i class="la la-user-alt"></i>Find jobs</a></li>
                <li><a href="/candidate-appliedjob"><i class="la la-briefcase"></i> Applied Jobs </a></li>
                <li><a href="/candidate-change-password"><i class="la la-lock"></i>Change Password</a></li>
             
        </ul>

      </div>
    </div>
    <!-- End User Sidebar -->

    <!--Page Title-->
    <section class="page-title style-two">
      
       
      <div class="auto-container">
         <div class="upper-title-box">
          <h3>Find Jobs</h3>
          <div class="text">Ready to jump back in?</div>
        </div>
        <!-- Job Search Form -->
        <div class="job-search-form">
          <form method="post" action="job-list-v10.html">
            <div class="row">
              <!-- Form Group -->
              <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <span class="icon flaticon-search-1"></span>
                <input type="text" name="field_name" placeholder="Job title, keywords, or company">
              </div>

              <!-- Form Group -->
              <div class="form-group col-lg-3 col-md-12 col-sm-12 location">
                <span class="icon flaticon-map-locator"></span>
                <input type="text" name="field_name" placeholder="City or postcode">
              </div>

              <!-- Form Group -->
              <div class="form-group col-lg-3 col-md-12 col-sm-12 location">
                <span class="icon flaticon-briefcase"></span>
                <select class="chosen-select">
                  <option value="">All Categories</option>
                  <option value="44">Accounting / Finance</option>
                  <option value="106">Automotive Jobs</option>
                  <option value="46">Customer</option>
                  <option value="48">Design</option>
                  <option value="47">Development</option>
                  <option value="45">Health and Care</option>
                  <option value="105">Marketing</option>
                  <option value="107">Project Management</option>
                </select>
              </div>

              <!-- Form Group -->
              <div class="form-group col-lg-2 col-md-12 col-sm-12 text-right">
                <button type="submit" class="theme-btn btn-style-one">Find Jobs</button>
              </div>
            </div>
          </form>
        </div>
        <!-- Job Search Form -->
      </div>
    </section>
    <!--End Page Title-->

    <!-- Listing Section -->
    <section class="ls-section">
      <div class="auto-container">
        <div class="filters-backdrop"></div>

        <div class="row">
          <!-- Content Column -->
          <div class="content-column col-lg-12">
            <div class="ls-outer">
              <!-- ls Switcher -->
              <div class="ls-switcher">
                <div class="showing-result">
                  <div class="top-filters">
                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Job Type</option>
                        <option>New Jobs</option>
                        <option>Freelance</option>
                        <option>Full Time</option>
                        <option>Internship</option>
                        <option>Part Time</option>
                        <option>Temporary</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Date Posted</option>
                        <option>New Jobs</option>
                        <option>Freelance</option>
                        <option>Full Time</option>
                        <option>Internship</option>
                        <option>Part Time</option>
                        <option>Temporary</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Experience Level</option>
                        <option>New Jobs</option>
                        <option>Freelance</option>
                        <option>Full Time</option>
                        <option>Internship</option>
                        <option>Part Time</option>
                        <option>Temporary</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <select class="chosen-select">
                        <option>Salary estimate</option>
                        <option>New Jobs</option>
                        <option>Freelance</option>
                        <option>Full Time</option>
                        <option>Internship</option>
                        <option>Part Time</option>
                        <option>Temporary</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="sort-by">
                  <select class="chosen-select">
                    <option>New Jobs</option>
                    <option>Freelance</option>
                    <option>Full Time</option>
                    <option>Internship</option>
                    <option>Part Time</option>
                    <option>Temporary</option>
                  </select>

                  <select class="chosen-select">
                    <option>Show 10</option>
                    <option>Show 20</option>
                    <option>Show 30</option>
                    <option>Show 40</option>
                    <option>Show 50</option>
                    <option>Show 60</option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div v-for="job in  ano_jobs" :key="job.id" class="job-block col-lg-6 ">
                  <div  @click="openjob(job.uuid)"  class="inner-box">
                    <div class="content">
                      <span class="company-logo"><img src="images/resource/company-logo/1-1.png" alt=""></span>
                      <h4 class="c-text-left" >{{job.job_title}}</h4>
                      <ul class="job-info">
                        <li><span class="icon flaticon-briefcase"></span>{{job.recruiter.org_name}}</li>
                        <li><span class="icon flaticon-map-locator"></span>{{job.location}}</li>
                        <li><span class="icon flaticon-clock-3"></span> Expire date:{{job.expire_date}}</li>
                        <li><span class="icon flaticon-money"></span> &pound;{{job.salary_range[0]}}-&pound;{{job.salary_range[1]}}</li>
                      </ul>
                      <ul class="job-other-info">
                        <li class="time">Fulltime</li>
                        <li class="privacy">Private</li>
                        <li class="required">Urgent</li>
                      </ul>
                      <button class="bookmark-btn"><span class="flaticon-bookmark"></span></button>
                    </div>
                  </div>
                </div>
 
              </div>

              <!-- Pagination -->
              <nav class="ls-pagination">
                <ul>
                  <li class="prev"><a href="#"><i class="fa fa-arrow-left"></i></a></li>
                  <li><a href="#">1</a></li>
                  <li><a href="#" class="current-page">2</a></li>
                  <li><a href="#">3</a></li>
                  <li class="next"><a href="#"><i class="fa fa-arrow-right"></i></a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End Listing Page Section -->

    </div>
</template>

<script>
import axios from 'axios'
export default {

    name:'CandidateFindJobsView',
    data() {
        return {
            jobs: [
                { jobtitle: 'Software Developer', segmemt: 'segmemt', location: 'Dhaka', time: '11',salary:'35k',type:'Fulltime' },
                { jobtitle: 'Product Manager', segmemt: 'segmemt', location: 'Khulna', time: '12',salary:'30k',type:'Fulltime' },
                { jobtitle: 'HR', segmemt: 'segmemt', location: 'Chandpur', time: '13',salary:'35k',type:'Parttime' },
                { jobtitle: 'Accounts', segmemt: 'segmemt', location: 'Dhaka', time: '11',salary:'25k',type:'Fulltime' },
            
            ],
            ano_jobs:[],
                        
        };
    },

methods: {
     

get_jobs() { axios.get("anonymous/job-list/",{ignore:"1"}).then( res => {


if(res.status==200){
  this.ano_jobs=res.data.results

  console.log("ok")
   }
}
)
.catch(error => {
console.log(error.response)
});
},


openjob(uuid){



  if(uuid!=null){

      this.$router.push({  name: 'job-single', query: { uuid: uuid } });

     
  }
     
}


},
  
mounted: function(){
   
   this.get_jobs()
    
}  
}
</script>

<style>

</style>